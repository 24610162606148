<template>
	<div class="card col-6 col-sm-6 col-md-4 col-lg-2">
		<div class="card-img-top py-3" style="background-color: black !important">
			<svg style="width: 5vh; height: 5vh" viewBox="0 0 24 24">
				<path fill="currentColor" :d="d" />
			</svg>
			<p>{{ label }}</p>
		</div>
		<div class="card-body">
			<p class="card-text">
				{{ values.join(", ") }}
			</p>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TraitCard",
		props: {
			d: {
				type: String
			},
			label: {
				type: String
			},
			values: {
				type: Array
			}
		}
	};
</script>

<style scoped></style>
