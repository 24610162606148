<template>
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="staticBackdropLabel">Available Attributes</h5>
				<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
			</div>
			<div class="modal-body">
				<div class="accordion" id="accordionExample">
					<div class="accordion-item" v-for="(value, filter, index) in availableFilters" v-bind:key="filter">
						<h2 class="accordion-header" :id="'heading' + index">
							<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse' + index" aria-expanded="false">
								{{ filter }} <span class="badge bg-secondary ms-2" v-if="selectedFilters[filter].length > 0"> {{ selectedFilters[filter].length }} </span>
							</button>
						</h2>
						<div :id="'collapse' + index" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
							<div class="accordion-body">
								<div class="form-check" v-for="v in value" v-bind:key="v">
									<input class="form-check-input" v-model="selectedFilters[filter]" type="checkbox" :value="v" :id="v" />
									<label class="form-check-label" :for="v">
										{{ v }}
									</label>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn btn-primary" @click="setFilter">Apply</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "FilterDialog",
		emits: ["hide"],
		props: {
			availableFilters: {
				type: Object,
				required: true
			},
			selectedFilters: {
				type: Object,
				required: true
			}
		},
		methods: {
			setFilter() {
				this.$emit("hide", this.selectedFilters);
			}
		}
	};
</script>

<style scoped></style>
