<template>
	<div class="container-fluid my-5">
		<!-- Content here -->
		<div class="card text-white gallery-text" :id="'gallery' + order">
			<div class="card-body">
				<div :id="'gallery' + level + 'carousel'" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active">
							<div class="container-fluid text-center">
								<br />
								<button class="btn btn-lg" style="text-decoration: none; color: white">
									<router-link :to="{ name: 'Level', params: { level: level } }">
										<h3 class="card-title">
											Level {{ level }}
											<svg style="width: 1em; height: 1em" viewBox="0 0 24 24">
												<path
													fill="white"
													d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z"
												/>
											</svg>
										</h3>
									</router-link>
								</button>

								<br /><br />
								<div class="row">
									<div class="col-md-12 col-lg-9">
										<div class="myspace card footer-card card-block">
											<h4 class="card-title py-3 bg-dark">Available Traits</h4>
											<div class="card-text">
												<div class="row myspace">
													<TraitCard
														d="M12,1C7,1 3,5 3,10V17A3,3 0 0,0 6,20H9V12H5V10A7,7 0 0,1 12,3A7,7 0 0,1 19,10V12H15V20H18A3,3 0 0,0 21,17V10C21,5 16.97,1 12,1Z"
														label="Headphones"
														:values="traits.Headphones"
													></TraitCard>
													<TraitCard
														d="M19.5 1L18.41 3.41L16 4.5L18.41 5.59L19.5 8L20.6 5.59L23 4.5L20.6 3.41L19.5 1M12 2C6.5 2 2 6.5 2 12V22H22V12C22 10.53 21.67 9.13 21.1 7.87L19.86 10.59C19.94 11.05 20 11.5 20 12C20 16.43 16.43 20 12 20C7.57 20 4 16.43 4 12C4 11.96 4 11.91 4 11.87A10 10 0 0 0 9.74 6.31A10 10 0 0 0 17.5 10A10 10 0 0 0 18.83 9.91L17.35 6.65L12.6 4.5L16.13 2.9C14.87 2.33 13.47 2 12 2M9 11.75A1.25 1.25 0 0 0 7.75 13A1.25 1.25 0 0 0 9 14.25A1.25 1.25 0 0 0 10.25 13A1.25 1.25 0 0 0 9 11.75M15 11.75A1.25 1.25 0 0 0 13.75 13A1.25 1.25 0 0 0 15 14.25A1.25 1.25 0 0 0 16.25 13A1.25 1.25 0 0 0 15 11.75Z"
														label="Test Subject"
														:values="traits['Test Subject']"
													></TraitCard>

													<TraitCard
														d="M7,17H4C2.38,17 0.96,15.74 0.76,14.14L0.26,11.15C0.15,10.3 0.39,9.5 0.91,8.92C1.43,8.34 2.19,8 3,8H9C9.83,8 10.58,8.35 11.06,8.96C11.17,9.11 11.27,9.27 11.35,9.45C11.78,9.36 12.22,9.36 12.64,9.45C12.72,9.27 12.82,9.11 12.94,8.96C13.41,8.35 14.16,8 15,8H21C21.81,8 22.57,8.34 23.09,8.92C23.6,9.5 23.84,10.3 23.74,11.11L23.23,14.18C23.04,15.74 21.61,17 20,17H17C15.44,17 13.92,15.81 13.54,14.3L12.64,11.59C12.26,11.31 11.73,11.31 11.35,11.59L10.43,14.37C10.07,15.82 8.56,17 7,17Z"
														label="Eyewear"
														:values="traits.Eyewear"
													></TraitCard>

													<TraitCard
														d="M16,9H19L14,16M10,9H14L12,17M5,9H8L10,16M15,4H17L19,7H16M11,4H13L14,7H10M7,4H9L8,7H5M6,2L2,8L12,22L22,8L18,2H6Z"
														label="Earrings"
														:values="traits.Earrings"
													></TraitCard>
													<TraitCard
														d="M15,18C11.68,18 9,15.31 9,12C9,8.68 11.68,6 15,6A6,6 0 0,1 21,12A6,6 0 0,1 15,18M4,13A1,1 0 0,1 3,12A1,1 0 0,1 4,11A1,1 0 0,1 5,12A1,1 0 0,1 4,13M22,3H7.63C6.97,3 6.38,3.32 6,3.81L0,12L6,20.18C6.38,20.68 6.97,21 7.63,21H22A2,2 0 0,0 24,19V5C24,3.89 23.1,3 22,3M13,11A1,1 0 0,0 14,10A1,1 0 0,0 13,9A1,1 0 0,0 12,10A1,1 0 0,0 13,11M15,16C16.86,16 18.35,14.72 18.8,13H11.2C11.65,14.72 13.14,16 15,16M17,11A1,1 0 0,0 18,10A1,1 0 0,0 17,9A1,1 0 0,0 16,10A1,1 0 0,0 17,11Z"
														label="Mood"
														:values="traits.Mood"
													></TraitCard>

													<TraitCard
														d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M9,10C8.26,10 7.61,10.4 7.27,11C7.1,10.71 7,10.36 7,10A2,2 0 0,1 9,8C10.11,8 11,8.9 11,10C11,10.36 10.9,10.71 10.73,11C10.39,10.4 9.74,10 9,10M17,10C17,10.36 16.9,10.71 16.73,11C16.39,10.4 15.74,10 15,10C14.26,10 13.61,10.4 13.27,11C13.1,10.71 13,10.36 13,10A2,2 0 0,1 15,8C16.11,8 17,8.9 17,10M16,13V15H15C15,17 14.1,18 13,18C11.9,18 11,17 11,15H8V13H16Z"
														label="Trippy Gum Flavour"
														:values="traits['Trippy Gum Flavour']"
													></TraitCard>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-12 col-lg-3 pt-sm-3 pt-md-0">
										<div class="myspace card card-block text-xs-center mb-3">
											<h4 class="card-title bg-danger py-2">Rarest Trait in this Level</h4>
											<div class="card-text py-2 h5">{{ rarest.type }} : {{ rarest.trait }} ({{ rarest.rarity }}%)</div>
										</div>
										<div class="myspace card card-block text-xs-center my-3">
											<h4 class="card-title bg-success py-2">Unlockable Content</h4>
											<div class="card-text py-2 h5">{{ unlockableContent }}</div>
										</div>
										<div class="myspace card card-block text-xs-center my-3">
											<h4 class="card-title bg-primary py-2">Price in ETH</h4>
											<div class="card-text py-2 h5">{{ price }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { generateFilters, getLevelIds, getMetadata, releaseMapping } from "@/utility/commonMethods";
	import TraitCard from "@/components/TraitCard";

	export default {
		name: "CommonCarouselItem",
		components: { TraitCard },
		props: {
			level: {
				type: String,
				required: true
			},
			order: {
				type: String,
				required: true
			},
			unlockableContent: {
				type: String,
				required: true
			},
			price: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				traits: {
					Mood: [],
					Eyewear: [],
					Headphones: [],
					Earrings: [],
					"Test Subject": [],
					"Trippy Gum Flavour": []
				},
				rarest: {
					type: "",
					trait: "",
					rarity: 0.0
				},
				releaseMapping: releaseMapping
			};
		},
		mounted() {
			let ids = getLevelIds(this.level);
			let metadata = getMetadata(ids);
			this.traits = generateFilters(metadata);
			this.getMinimumRarity();
		},
		methods: {
			getMinimumRarity() {
				let rarity = require("../assets/rarity.json");
				let traitRarity = [];

				for (let traitsKey in this.traits) {
					let traits = this.traits[traitsKey];
					traits.forEach((trait) => {
						let tmp = rarity[traitsKey].reduce((result, r) => {
							if (r.trait === trait) {
								result.push({
									type: traitsKey,
									trait: trait,
									rarity: parseFloat(r.occurrence.split("%")[0])
								});
							}
							return result;
						}, []);
						traitRarity = traitRarity.concat(tmp);
					});
				}
				this.rarest = traitRarity.reduce(function (prev, curr) {
					return prev.rarity < curr.rarity ? prev : curr;
				});
			}
		}
	};
</script>

<style scoped>
	.gallery-text {
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	.myspace {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		margin: 0 10px 0 10px;
		font-family: "Mukta Vaani", "cursive";
	}

	.card {
		background-color: rgba(255, 255, 255, 0.1) !important;
	}

	h3 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 3em;
	}

	.footer-list {
		text-align: left;
	}

	i,
	svg,
	use {
		width: 1em;
		height: 1em;
	}

	a {
		text-decoration: none;
	}

	ul.footer-list,
	ul.footer-list a {
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-family: "Mukta Vaani", "cursive";
		font-weight: 400;
		font-size: 1em;
		text-decoration: none;
		color: white;
	}
</style>
