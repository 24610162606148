<template>
	<div class="myspace card card-block text-xs-center">
		<h4 class="card-title mb-1">
			<a :href="getBuyURL()" class="btn btn-warning btn-block btn-title w-100" target="_blank">
				<span v-if="isAvailable()">Buy</span>
				<span v-else>Sold</span>
				<br />
				Trippy Gum #{{ id }}
			</a>
		</h4>
		<img v-lazy="metadata.file_url" class="img-fluid img-thumbnail" alt="" />
	</div>
</template>

<script>
	export default {
		name: "TrippyGumCard",
		props: {
			metadata: {
				type: Object
			},
			id: {
				type: String
			},
			level: {
				type: String
			}
		},
		methods: {
			isAvailable() {
				let soldIds = ["35", "1619"];
				return !soldIds.includes(this.id);
			},
			getBuyURL() {
				return `https://opensea.io/assets/matic/0x6f544b9b643fa9fbef284dd4b09c5e010d4c2faf/${this.id}`;
			}
		}
	};
</script>

<style scoped>
	.btn-title {
		letter-spacing: 5px;
	}
</style>
