<template>
	<div class="col-12 col-sm-12 col-md-6 col-lg-3 me-sm-auto">
		<div class="myspace card footer-card card-block text-xs-center">
			<div class="card-text p-5">
				<h4>{{ name }}</h4>
				<img v-lazy="img" class="rounded-circle img-fluid mx-auto d-block pb-2" />
				<div>
					<h4>
						{{ title }} <br />
						at Noob-chan's NFTs
					</h4>
					<br />
					<h6>({{ subTitle }})</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "TeamMember",
		props: {
			img: {
				type: String
			},
			name: {
				type: String
			},
			title: {
				type: String
			},
			subTitle: {
				type: String
			}
		}
	};
</script>

<style scoped>
	.myspace {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		margin: 0 1px 0 1px;
	}

	.card {
		background-color: rgba(255, 255, 255, 0.1) !important;
	}

	.footer-card {
		background-color: rgba(255, 255, 255, 0) !important;
	}
</style>
