let metadata = require("../assets/_metadata.json");

export function getLevelIds(level) {
	let urls = require(`../assets/level${level}links.txt`);
	urls = urls.default.trim().split("\n");
	return urls.map((url) => {
		let id = -1;
		let tmp = url.split("thumbnails/");
		if (tmp.length === 2) {
			if (url.includes(".png")) {
				id = tmp[1].split(".png")[0];
			} else {
				id = tmp[1].split(".gif")[0];
			}
		}
		if (id === -1) {
			console.error("could not find for: " + url);
		}

		return id;
	});
}

export function getMetadata(ids) {
	let meta = [];
	ids.forEach((id) => {
		meta.push({
			id: id,
			metadata: metadata.find((m) => m.name === `Trippy Gum #${id}`)
		});
	});

	return meta;
}

export function generateFilters(metadata) {
	let traits = {
		Mood: [],
		Eyewear: [],
		Headphones: [],
		Earrings: [],
		"Test Subject": [],
		"Trippy Gum Flavour": []
	};

	metadata.forEach((mdc) => {
		mdc.metadata.attributes.forEach((attr) => {
			traits[attr.trait_type].push(attr.value);
		});
	});

	for (let availableFiltersKey in traits) {
		traits[availableFiltersKey] = [...new Set(traits[availableFiltersKey])];
	}

	return traits;
}

export const releaseMapping = {
	1: 11,
	2: 12,
	3: 13,
	4: 14,
	5: 15,
	6: 15,
	7: 14,
	8: 13,
	9: 12,
	10: 11
};
