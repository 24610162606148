<template>
	<div class="container-fluid text-center py-5">
		<div class="row">
			<div class="col-6 col-sm-6 col-md-6 pb-4 pb-sm-4 pb-md-0" v-for="id in ids" v-bind:key="id">
				<div class="myspace card card-block text-xs-center">
					<div class="card-text"><img v-lazy="'https://d2jn1mvjd9iux5.cloudfront.net/thumbnails/' + id" class="img-fluid rounded-start" alt="..." /></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "CarouselItem",
		props: {
			ids: {
				type: Array
			}
		}
	};
</script>

<style scoped>
	.myspace {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		margin: 0 10px 0 10px;
	}

	h3 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 3em;
	}

	a {
		text-decoration: none;
	}
</style>
