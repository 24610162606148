<template>
	<div class="container-fluid topDiv">
		<div id="stars"></div>
		<div id="stars2"></div>
		<div id="stars3"></div>
		<div class="lines">
			<div class="line"></div>
			<div class="line"></div>
			<div class="line"></div>
		</div>

		<div id="homelink">
			<router-link to="/"><h4>HOME</h4></router-link>
		</div>
		<div>
			<div id="title">
				<h1>Trippy Gum</h1>
				<br />
				<h3>Level {{ level }}</h3>
			</div>
		</div>
		<div class="container-fluid">
			<!-- Content here -->

			<div class="card text-white" id="gallery1">
				<div class="card-body">
					<div class="mb-2 row g-3 justify-content-start">
						<button type="button" class="btn btn-primary col-12 col-sm-6 col-md-4 col-lg-1 m-2" @click="showModal">
							Filters
							<span class="badge rounded-pill bg-dark"> {{ filterBadge }} </span>
						</button>

						<button type="button" class="btn btn-outline-light col-12 col-sm-6 col-lg-1 m-2" v-if="filterBadge > 0" @click="clearFilters">Clear</button>
					</div>
					<div class="row">
						<div class="col-6 col-sm-6 col-md-4 col-lg-2 mb-4" v-for="mdc in metadataCollection" v-bind:key="mdc.id">
							<TrippyGumCard :id="mdc.id" :metadata="mdc.metadata" :level="level"></TrippyGumCard>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<!-- Content here -->
			<Footer></Footer>
		</div>

		<div class="modal fade" id="filterModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<FilterDialog :available-filters="availableFilters" :selected-filters="selectedFilters" @hide="filterSelection"></FilterDialog>
		</div>
	</div>
</template>

<script>
	import { generateFilters, getLevelIds, getMetadata, releaseMapping } from "@/utility/commonMethods";
	import TrippyGumCard from "@/components/TrippyGumCard";
	import FilterDialog from "@/components/FilterDialog";
	import { Modal } from "bootstrap";
	import Footer from "@/components/Footer";

	export default {
		name: "Level",
		components: { Footer, FilterDialog, TrippyGumCard },
		mounted() {
			this.level = this.$route.params.level;
			this.ids = getLevelIds(this.level);
			this.getData(this.ids);
			this.unfilteredMetadataCollection = this.metadataCollection;
			this.availableFilters = generateFilters(this.unfilteredMetadataCollection);
			this.filterModal = new Modal(document.getElementById("filterModal"));
		},
		methods: {
			showModal() {
				this.filterModal.show();
			},
			filterSelection(selectedFilters) {
				this.filterModal.hide();

				let filteredIds = [];

				this.unfilteredMetadataCollection.forEach((mdc) => {
					let found = true;
					for (let trait in selectedFilters) {
						let attribute = selectedFilters[trait];
						if (attribute.length > 0) {
							let tmp = mdc.metadata.attributes.find((attr) => attr.trait_type === trait);
							if (!attribute.includes(tmp.value)) {
								found = false;
								break;
							}
						}
					}

					if (found) {
						filteredIds.push(mdc.id);
					}
				});
				filteredIds = [...new Set(filteredIds)];
				this.getData(filteredIds);
			},
			clearFilters() {
				for (let selectedFiltersKey in this.selectedFilters) {
					this.selectedFilters[selectedFiltersKey] = [];
				}
				this.getData(this.ids);
			},
			getData(ids) {
				this.metadataCollection = getMetadata(ids);
			}
		},
		computed: {
			filterBadge() {
				let count = 0;

				for (let selectedFiltersKey in this.selectedFilters) {
					if (this.selectedFilters[selectedFiltersKey].length > 0) {
						count++;
					}
				}
				return count;
			}
		},
		data() {
			return {
				availableFilters: {
					Mood: [],
					"Test Subject": [],
					Earrings: [],
					Headphones: [],
					Eyewear: [],
					"Trippy Gum Flavour": []
				},
				selectedFilters: {
					Mood: [],
					"Test Subject": [],
					Earrings: [],
					Headphones: [],
					Eyewear: [],
					"Trippy Gum Flavour": []
				},
				ids: [],
				filterModal: null,
				level: 1,
				unfilteredMetadataCollection: [],
				metadataCollection: [],
				releaseMapping: releaseMapping
			};
		}
	};
</script>

<style scoped>
	#homelink {
		width: 5vw;
	}

	#homelink a {
		padding-left: 20px;
		text-decoration: none;
		color: white;
		font-family: "Mukta Vaani", "cursive";
		position: relative;
	}

	#filterModal {
		font-family: "Mukta Vaani", "cursive";
	}

	.lines {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 100%;
		margin: auto;
		width: 90vw;
	}

	.line {
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		left: 50%;
		background: rgba(255, 255, 255, 0);
		overflow: hidden;
	}

	.line::after {
		content: "";
		display: block;
		position: absolute;
		height: 15vh;
		width: 100%;
		top: -50%;
		left: 0;
		background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
		animation: drop 7s 0s infinite;
		animation-fill-mode: forwards;
		animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
	}

	.line:nth-child(1) {
		margin-left: -25%;
	}

	.line:nth-child(1)::after {
		animation-delay: 2s;
	}

	.line:nth-child(3) {
		margin-left: 25%;
	}

	.line:nth-child(3)::after {
		animation-delay: 2.5s;
	}

	@keyframes drop {
		0% {
			top: 0%;
		}
		100% {
			top: 110%;
		}
	}

	.topDiv {
		background: black;
		display: table;
		width: 100%;
		color: #000;
		line-height: 1.6;
		position: relative;
	}

	h5 {
		color: white;
		font-family: "Mukta Vaani", "cursive";
		font-weight: 400;
		font-size: 1.5em;
	}

	#stars {
		width: 1px;
		height: 1px;
		background: transparent;
		box-shadow: 1vw 48vh #fff, 2vw 56vh #fff, 3vw 459vh #fff, 4vw 104vh #fff, 5vw 201vh #fff, 6vw 506vh #fff, 7vw 660vh #fff, 8vw 435vh #fff, 9vw 396vh #fff,
			10vw 278vh #fff, 11vw 587vh #fff, 12vw 193vh #fff, 13vw 585vh #fff, 14vw 54vh #fff, 15vw 394vh #fff, 16vw 112vh #fff, 17vw 157vh #fff, 18vw 644vh #fff,
			19vw 146vh #fff, 20vw 551vh #fff, 21vw 325vh #fff, 22vw 168vh #fff, 23vw 507vh #fff, 24vw 125vh #fff, 25vw 231vh #fff, 26vw 639vh #fff, 27vw 98vh #fff,
			28vw 321vh #fff, 29vw 466vh #fff, 30vw 421vh #fff, 31vw 36vh #fff, 32vw 30vh #fff, 33vw 26vh #fff, 34vw 545vh #fff, 35vw 493vh #fff, 36vw 641vh #fff,
			37vw 73vh #fff, 38vw 65vh #fff, 39vw 678vh #fff, 40vw 661vh #fff, 41vw 638vh #fff, 42vw 336vh #fff, 43vw 279vh #fff, 44vw 252vh #fff, 45vw 340vh #fff,
			46vw 542vh #fff, 47vw 66vh #fff, 48vw 82vh #fff, 49vw 465vh #fff, 50vw 378vh #fff, 51vw 310vh #fff, 52vw 450vh #fff, 53vw 60vh #fff, 54vw 243vh #fff,
			55vw 221vh #fff, 56vw 209vh #fff, 57vw 313vh #fff, 58vw 659vh #fff, 59vw 94vh #fff, 60vw 8vh #fff, 61vw 609vh #fff, 62vw 410vh #fff, 63vw 24vh #fff,
			64vw 622vh #fff, 65vw 665vh #fff, 66vw 311vh #fff, 67vw 700vh #fff, 68vw 440vh #fff, 69vw 110vh #fff, 70vw 62vh #fff, 71vw 543vh #fff, 72vw 144vh #fff,
			73vw 44vh #fff, 74vw 566vh #fff, 75vw 395vh #fff, 76vw 409vh #fff, 77vw 367vh #fff, 78vw 433vh #fff, 79vw 46vh #fff, 80vw 225vh #fff, 81vw 501vh #fff,
			82vw 461vh #fff, 83vw 299vh #fff, 84vw 658vh #fff, 85vw 300vh #fff, 86vw 109vh #fff, 87vw 630vh #fff, 88vw 191vh #fff, 89vw 172vh #fff, 90vw 295vh #fff,
			91vw 607vh #fff, 92vw 292vh #fff, 93vw 134vh #fff, 94vw 620vh #fff, 95vw 535vh #fff, 96vw 61vh #fff, 97vw 559vh #fff, 98vw 458vh #fff, 99vw 377vh #fff,
			100vw 258vh #fff, 1vw 308vh #fff, 2vw 226vh #fff, 3vw 153vh #fff, 4vw 446vh #fff, 5vw 352vh #fff, 6vw 436vh #fff, 7vw 663vh #fff, 8vw 102vh #fff,
			9vw 403vh #fff, 10vw 126vh #fff, 11vw 426vh #fff, 12vw 91vh #fff, 13vw 487vh #fff, 14vw 42vh #fff, 15vw 359vh #fff, 16vw 631vh #fff, 17vw 503vh #fff,
			18vw 76vh #fff, 19vw 619vh #fff, 20vw 240vh #fff, 21vw 277vh #fff, 22vw 577vh #fff, 23vw 602vh #fff, 24vw 556vh #fff, 25vw 371vh #fff, 26vw 32vh #fff,
			27vw 656vh #fff, 28vw 470vh #fff, 29vw 200vh #fff, 30vw 357vh #fff, 31vw 389vh #fff, 32vw 586vh #fff, 33vw 190vh #fff, 34vw 480vh #fff, 35vw 320vh #fff,
			36vw 567vh #fff, 37vw 56vh #fff, 38vw 439vh #fff, 39vw 192vh #fff, 40vw 691vh #fff, 41vw 681vh #fff, 42vw 111vh #fff, 43vw 238vh #fff, 44vw 198vh #fff,
			45vw 689vh #fff, 46vw 511vh #fff, 47vw 206vh #fff, 48vw 448vh #fff, 49vw 69vh #fff, 50vw 427vh #fff, 51vw 528vh #fff, 52vw 468vh #fff, 53vw 351vh #fff,
			54vw 108vh #fff, 55vw 675vh #fff, 56vw 301vh #fff, 57vw 86vh #fff, 58vw 684vh #fff, 59vw 662vh #fff, 60vw 139vh #fff, 61vw 181vh #fff, 62vw 590vh #fff,
			63vw 404vh #fff, 64vw 502vh #fff, 65vw 53vh #fff, 66vw 296vh #fff, 67vw 362vh #fff, 68vw 596vh #fff, 69vw 343vh #fff, 70vw 515vh #fff, 71vw 443vh #fff,
			72vw 3vh #fff, 73vw 133vh #fff, 74vw 588vh #fff, 75vw 591vh #fff, 76vw 20vh #fff, 77vw 419vh #fff, 78vw 215vh #fff, 79vw 10vh #fff, 80vw 525vh #fff,
			81vw 235vh #fff, 82vw 540vh #fff, 83vw 614vh #fff, 84vw 432vh #fff, 85vw 407vh #fff, 86vw 460vh #fff, 87vw 18vh #fff, 88vw 254vh #fff, 89vw 621vh #fff,
			90vw 445vh #fff, 91vw 165vh #fff, 92vw 489vh #fff, 93vw 195vh #fff, 94vw 183vh #fff, 95vw 304vh #fff, 96vw 575vh #fff, 97vw 492vh #fff, 98vw 286vh #fff,
			99vw 422vh #fff, 100vw 342vh #fff, 1vw 592vh #fff, 2vw 135vh #fff, 3vw 454vh #fff, 4vw 213vh #fff, 5vw 93vh #fff, 6vw 259vh #fff, 7vw 170vh #fff,
			8vw 233vh #fff, 9vw 680vh #fff, 10vw 344vh #fff, 11vw 150vh #fff, 12vw 188vh #fff, 13vw 38vh #fff, 14vw 285vh #fff, 15vw 564vh #fff, 16vw 384vh #fff,
			17vw 290vh #fff, 18vw 392vh #fff, 19vw 697vh #fff, 20vw 182vh #fff, 21vw 370vh #fff, 22vw 634vh #fff, 23vw 253vh #fff, 24vw 418vh #fff, 25vw 555vh #fff,
			26vw 12vh #fff, 27vw 444vh #fff, 28vw 244vh #fff, 29vw 203vh #fff, 30vw 413vh #fff, 31vw 560vh #fff, 32vw 668vh #fff, 33vw 569vh #fff, 34vw 14vh #fff,
			35vw 428vh #fff, 36vw 87vh #fff, 37vw 288vh #fff, 38vw 57vh #fff, 39vw 210vh #fff, 40vw 495vh #fff, 41vw 39vh #fff, 42vw 457vh #fff, 43vw 156vh #fff,
			44vw 68vh #fff, 45vw 205vh #fff, 46vw 549vh #fff, 47vw 523vh #fff, 48vw 499vh #fff, 49vw 530vh #fff, 50vw 554vh #fff, 51vw 414vh #fff, 52vw 683vh #fff,
			53vw 268vh #fff, 54vw 25vh #fff, 55vw 536vh #fff, 56vw 207vh #fff, 57vw 119vh #fff, 58vw 561vh #fff, 59vw 537vh #fff, 60vw 280vh #fff, 61vw 623vh #fff,
			62vw 677vh #fff, 63vw 21vh #fff, 64vw 474vh #fff, 65vw 276vh #fff, 66vw 412vh #fff, 67vw 534vh #fff, 68vw 101vh #fff, 69vw 128vh #fff, 70vw 305vh #fff,
			71vw 332vh #fff, 72vw 608vh #fff, 73vw 498vh #fff, 74vw 113vh #fff, 75vw 563vh #fff, 76vw 4vh #fff, 77vw 441vh #fff, 78vw 477vh #fff, 79vw 15vh #fff,
			80vw 520vh #fff, 81vw 186vh #fff, 82vw 469vh #fff, 83vw 158vh #fff, 84vw 405vh #fff, 85vw 45vh #fff, 86vw 380vh #fff, 87vw 236vh #fff, 88vw 346vh #fff,
			89vw 141vh #fff, 90vw 582vh #fff, 91vw 250vh #fff, 92vw 176vh #fff, 93vw 171vh #fff, 94vw 603vh #fff, 95vw 400vh #fff, 96vw 6vh #fff, 97vw 211vh #fff,
			98vw 571vh #fff, 99vw 64vh #fff, 100vw 327vh #fff, 1vw 524vh #fff, 2vw 120vh #fff, 3vw 699vh #fff, 4vw 505vh #fff, 5vw 5vh #fff, 6vw 550vh #fff,
			7vw 452vh #fff, 8vw 372vh #fff, 9vw 497vh #fff, 10vw 494vh #fff, 11vw 548vh #fff, 12vw 136vh #fff, 13vw 293vh #fff, 14vw 690vh #fff, 15vw 376vh #fff,
			16vw 154vh #fff, 17vw 449vh #fff, 18vw 390vh #fff, 19vw 83vh #fff, 20vw 685vh #fff, 21vw 629vh #fff, 22vw 55vh #fff, 23vw 34vh #fff, 24vw 78vh #fff,
			25vw 79vh #fff, 26vw 522vh #fff, 27vw 189vh #fff, 28vw 199vh #fff, 29vw 232vh #fff, 30vw 114vh #fff, 31vw 479vh #fff, 32vw 265vh #fff, 33vw 605vh #fff,
			34vw 267vh #fff, 35vw 337vh #fff, 36vw 484vh #fff, 37vw 143vh #fff, 38vw 504vh #fff, 39vw 628vh #fff, 40vw 595vh #fff, 41vw 281vh #fff, 42vw 294vh #fff,
			43vw 694vh #fff, 44vw 163vh #fff, 45vw 160vh #fff, 46vw 369vh #fff, 47vw 512vh #fff, 48vw 669vh #fff, 49vw 214vh #fff, 50vw 500vh #fff, 51vw 611vh #fff,
			52vw 672vh #fff, 53vw 568vh #fff, 54vw 686vh #fff, 55vw 140vh #fff, 56vw 671vh #fff, 57vw 129vh #fff, 58vw 333vh #fff, 59vw 319vh #fff, 60vw 664vh #fff,
			61vw 483vh #fff, 62vw 245vh #fff, 63vw 90vh #fff, 64vw 173vh #fff, 65vw 643vh #fff, 66vw 271vh #fff, 67vw 698vh #fff, 68vw 312vh #fff, 69vw 580vh #fff,
			70vw 583vh #fff, 71vw 355vh #fff, 72vw 85vh #fff, 73vw 175vh #fff, 74vw 353vh #fff, 75vw 491vh #fff, 76vw 601vh #fff, 77vw 531vh #fff, 78vw 574vh #fff,
			79vw 508vh #fff, 80vw 58vh #fff, 81vw 16vh #fff, 82vw 326vh #fff, 83vw 263vh #fff, 84vw 99vh #fff, 85vw 673vh #fff, 86vw 408vh #fff, 87vw 241vh #fff,
			88vw 138vh #fff, 89vw 358vh #fff, 90vw 676vh #fff, 91vw 196vh #fff, 92vw 692vh #fff, 93vw 486vh #fff, 94vw 654vh #fff, 95vw 70vh #fff, 96vw 348vh #fff,
			97vw 19vh #fff, 98vw 594vh #fff, 99vw 455vh #fff, 100vw 518vh #fff, 1vw 650vh #fff, 2vw 273vh #fff, 3vw 364vh #fff, 4vw 649vh #fff, 5vw 473vh #fff,
			6vw 682vh #fff, 7vw 216vh #fff, 8vw 612vh #fff, 9vw 317vh #fff, 10vw 657vh #fff, 11vw 27vh #fff, 12vw 88vh #fff, 13vw 145vh #fff, 14vw 423vh #fff,
			15vw 228vh #fff, 16vw 318vh #fff, 17vw 570vh #fff, 18vw 475vh #fff, 19vw 242vh #fff, 20vw 167vh #fff, 21vw 314vh #fff, 22vw 323vh #fff, 23vw 453vh #fff,
			24vw 124vh #fff, 25vw 224vh #fff, 26vw 169vh #fff, 27vw 302vh #fff, 28vw 521vh #fff, 29vw 49vh #fff, 30vw 152vh #fff, 31vw 13vh #fff, 32vw 303vh #fff,
			33vw 148vh #fff, 34vw 648vh #fff, 35vw 307vh #fff, 36vw 100vh #fff, 37vw 382vh #fff, 38vw 670vh #fff, 39vw 581vh #fff, 40vw 606vh #fff, 41vw 565vh #fff,
			42vw 386vh #fff, 43vw 185vh #fff, 44vw 184vh #fff, 45vw 52vh #fff, 46vw 576vh #fff, 47vw 50vh #fff, 48vw 653vh #fff, 49vw 194vh #fff, 50vw 75vh #fff,
			51vw 476vh #fff, 52vw 63vh #fff, 53vw 48vh #fff, 54vw 368vh #fff, 55vw 349vh #fff, 56vw 345vh #fff, 57vw 666vh #fff, 58vw 647vh #fff, 59vw 547vh #fff,
			60vw 220vh #fff, 61vw 230vh #fff, 62vw 625vh #fff, 63vw 557vh #fff, 64vw 37vh #fff, 65vw 31vh #fff, 66vw 610vh #fff, 67vw 624vh #fff, 68vw 272vh #fff,
			69vw 402vh #fff, 70vw 161vh #fff, 71vw 437vh #fff, 72vw 416vh #fff, 73vw 106vh #fff, 74vw 604vh #fff, 75vw 249vh #fff, 76vw 695vh #fff, 77vw 132vh #fff,
			78vw 74vh #fff, 79vw 248vh #fff, 80vw 347vh #fff, 81vw 130vh #fff, 82vw 131vh #fff, 83vw 218vh #fff, 84vw 517vh #fff, 85vw 81vh #fff, 86vw 137vh #fff,
			87vw 636vh #fff, 88vw 387vh #fff, 89vw 28vh #fff, 90vw 274vh #fff, 91vw 519vh #fff, 92vw 688vh #fff, 93vw 541vh #fff, 94vw 289vh #fff, 95vw 123vh #fff,
			96vw 558vh #fff, 97vw 516vh #fff, 98vw 471vh #fff, 99vw 438vh #fff, 100vw 96vh #fff, 1vw 84vh #fff, 2vw 147vh #fff, 3vw 526vh #fff, 4vw 578vh #fff,
			5vw 679vh #fff, 6vw 260vh #fff, 7vw 424vh #fff, 8vw 527vh #fff, 9vw 306vh #fff, 10vw 617vh #fff, 11vw 488vh #fff, 12vw 217vh #fff, 13vw 651vh #fff,
			14vw 80vh #fff, 15vw 72vh #fff, 16vw 652vh #fff, 17vw 116vh #fff, 18vw 1vh #fff, 19vw 544vh #fff, 20vw 573vh #fff, 21vw 284vh #fff, 22vw 251vh #fff,
			23vw 618vh #fff, 24vw 315vh #fff, 25vw 373vh #fff, 26vw 121vh #fff, 27vw 118vh #fff, 28vw 212vh #fff, 29vw 632vh #fff, 30vw 247vh #fff, 31vw 532vh #fff,
			32vw 626vh #fff, 33vw 122vh #fff, 34vw 222vh #fff, 35vw 237vh #fff, 36vw 179vh #fff, 37vw 67vh #fff, 38vw 103vh #fff, 39vw 264vh #fff, 40vw 693vh #fff,
			41vw 627vh #fff, 42vw 379vh #fff, 43vw 335vh #fff, 44vw 316vh #fff, 45vw 22vh #fff, 46vw 667vh #fff, 47vw 174vh #fff, 48vw 633vh #fff, 49vw 411vh #fff,
			50vw 472vh #fff, 51vw 425vh #fff, 52vw 383vh #fff, 53vw 324vh #fff, 54vw 266vh #fff, 55vw 282vh #fff, 56vw 270vh #fff, 57vw 287vh #fff, 58vw 11vh #fff,
			59vw 593vh #fff, 60vw 89vh #fff, 61vw 356vh #fff, 62vw 401vh #fff, 63vw 331vh #fff, 64vw 391vh #fff, 65vw 43vh #fff, 66vw 361vh #fff, 67vw 434vh #fff,
			68vw 204vh #fff, 69vw 77vh #fff, 70vw 467vh #fff, 71vw 615vh #fff, 72vw 600vh #fff, 73vw 166vh #fff, 74vw 155vh #fff, 75vw 399vh #fff, 76vw 598vh #fff,
			77vw 485vh #fff, 78vw 350vh #fff, 79vw 187vh #fff, 80vw 197vh #fff, 81vw 496vh #fff, 82vw 297vh #fff, 83vw 674vh #fff, 84vw 478vh #fff, 85vw 322vh #fff,
			86vw 178vh #fff, 87vw 539vh #fff, 88vw 298vh #fff, 89vw 514vh #fff, 90vw 584vh #fff, 91vw 162vh #fff, 92vw 229vh #fff, 93vw 41vh #fff, 94vw 597vh #fff,
			95vw 227vh #fff, 96vw 406vh #fff, 97vw 239vh #fff, 98vw 309vh #fff, 99vw 177vh #fff, 100vw 261vh #fff, 1vw 2vh #fff, 2vw 47vh #fff, 3vw 360vh #fff,
			4vw 509vh #fff, 5vw 334vh #fff, 6vw 29vh #fff, 7vw 339vh #fff, 8vw 127vh #fff, 9vw 417vh #fff, 10vw 269vh #fff, 11vw 553vh #fff, 12vw 572vh #fff,
			13vw 257vh #fff, 14vw 546vh #fff, 15vw 97vh #fff, 16vw 9vh #fff, 17vw 234vh #fff, 18vw 442vh #fff, 19vw 646vh #fff, 20vw 599vh #fff, 21vw 616vh #fff,
			22vw 180vh #fff, 23vw 35vh #fff, 24vw 363vh #fff, 25vw 366vh #fff, 26vw 374vh #fff, 27vw 71vh #fff, 28vw 33vh #fff, 29vw 330vh #fff, 30vw 329vh #fff,
			31vw 291vh #fff, 32vw 159vh #fff, 33vw 562vh #fff, 34vw 513vh #fff, 35vw 696vh #fff, 36vw 393vh #fff, 37vw 431vh #fff, 38vw 415vh #fff, 39vw 255vh #fff,
			40vw 219vh #fff, 41vw 381vh #fff, 42vw 7vh #fff, 43vw 275vh #fff, 44vw 655vh #fff, 45vw 59vh #fff, 46vw 375vh #fff, 47vw 365vh #fff, 48vw 481vh #fff,
			49vw 202vh #fff, 50vw 420vh #fff, 51vw 464vh #fff, 52vw 115vh #fff, 53vw 538vh #fff, 54vw 642vh #fff, 55vw 533vh #fff, 56vw 529vh #fff, 57vw 341vh #fff,
			58vw 687vh #fff, 59vw 429vh #fff, 60vw 637vh #fff, 61vw 463vh #fff, 62vw 208vh #fff, 63vw 256vh #fff, 64vw 246vh #fff, 65vw 283vh #fff, 66vw 447vh #fff,
			67vw 95vh #fff, 68vw 482vh #fff, 69vw 105vh #fff, 70vw 589vh #fff, 71vw 397vh #fff, 72vw 579vh #fff, 73vw 456vh #fff, 74vw 462vh #fff, 75vw 262vh #fff,
			76vw 385vh #fff, 77vw 92vh #fff, 78vw 151vh #fff, 79vw 430vh #fff, 80vw 635vh #fff, 81vw 223vh #fff, 82vw 164vh #fff, 83vw 490vh #fff, 84vw 613vh #fff,
			85vw 388vh #fff, 86vw 142vh #fff, 87vw 552vh #fff, 88vw 338vh #fff, 89vw 23vh #fff, 90vw 510vh #fff, 91vw 17vh #fff, 92vw 645vh #fff, 93vw 117vh #fff,
			94vw 398vh #fff, 95vw 328vh #fff, 96vw 107vh #fff, 97vw 149vh #fff, 98vw 354vh #fff, 99vw 40vh #fff, 100vw 51vh #fff;
		animation: animStar 100s linear infinite;
	}

	#stars:after {
		content: " ";
		position: absolute;
		width: 1px;
		height: 1px;
		background: transparent;
		box-shadow: 1vw 48vh #fff, 2vw 56vh #fff, 3vw 459vh #fff, 4vw 104vh #fff, 5vw 201vh #fff, 6vw 506vh #fff, 7vw 660vh #fff, 8vw 435vh #fff, 9vw 396vh #fff,
			10vw 278vh #fff, 11vw 587vh #fff, 12vw 193vh #fff, 13vw 585vh #fff, 14vw 54vh #fff, 15vw 394vh #fff, 16vw 112vh #fff, 17vw 157vh #fff, 18vw 644vh #fff,
			19vw 146vh #fff, 20vw 551vh #fff, 21vw 325vh #fff, 22vw 168vh #fff, 23vw 507vh #fff, 24vw 125vh #fff, 25vw 231vh #fff, 26vw 639vh #fff, 27vw 98vh #fff,
			28vw 321vh #fff, 29vw 466vh #fff, 30vw 421vh #fff, 31vw 36vh #fff, 32vw 30vh #fff, 33vw 26vh #fff, 34vw 545vh #fff, 35vw 493vh #fff, 36vw 641vh #fff,
			37vw 73vh #fff, 38vw 65vh #fff, 39vw 678vh #fff, 40vw 661vh #fff, 41vw 638vh #fff, 42vw 336vh #fff, 43vw 279vh #fff, 44vw 252vh #fff, 45vw 340vh #fff,
			46vw 542vh #fff, 47vw 66vh #fff, 48vw 82vh #fff, 49vw 465vh #fff, 50vw 378vh #fff, 51vw 310vh #fff, 52vw 450vh #fff, 53vw 60vh #fff, 54vw 243vh #fff,
			55vw 221vh #fff, 56vw 209vh #fff, 57vw 313vh #fff, 58vw 659vh #fff, 59vw 94vh #fff, 60vw 8vh #fff, 61vw 609vh #fff, 62vw 410vh #fff, 63vw 24vh #fff,
			64vw 622vh #fff, 65vw 665vh #fff, 66vw 311vh #fff, 67vw 700vh #fff, 68vw 440vh #fff, 69vw 110vh #fff, 70vw 62vh #fff, 71vw 543vh #fff, 72vw 144vh #fff,
			73vw 44vh #fff, 74vw 566vh #fff, 75vw 395vh #fff, 76vw 409vh #fff, 77vw 367vh #fff, 78vw 433vh #fff, 79vw 46vh #fff, 80vw 225vh #fff, 81vw 501vh #fff,
			82vw 461vh #fff, 83vw 299vh #fff, 84vw 658vh #fff, 85vw 300vh #fff, 86vw 109vh #fff, 87vw 630vh #fff, 88vw 191vh #fff, 89vw 172vh #fff, 90vw 295vh #fff,
			91vw 607vh #fff, 92vw 292vh #fff, 93vw 134vh #fff, 94vw 620vh #fff, 95vw 535vh #fff, 96vw 61vh #fff, 97vw 559vh #fff, 98vw 458vh #fff, 99vw 377vh #fff,
			100vw 258vh #fff, 1vw 308vh #fff, 2vw 226vh #fff, 3vw 153vh #fff, 4vw 446vh #fff, 5vw 352vh #fff, 6vw 436vh #fff, 7vw 663vh #fff, 8vw 102vh #fff,
			9vw 403vh #fff, 10vw 126vh #fff, 11vw 426vh #fff, 12vw 91vh #fff, 13vw 487vh #fff, 14vw 42vh #fff, 15vw 359vh #fff, 16vw 631vh #fff, 17vw 503vh #fff,
			18vw 76vh #fff, 19vw 619vh #fff, 20vw 240vh #fff, 21vw 277vh #fff, 22vw 577vh #fff, 23vw 602vh #fff, 24vw 556vh #fff, 25vw 371vh #fff, 26vw 32vh #fff,
			27vw 656vh #fff, 28vw 470vh #fff, 29vw 200vh #fff, 30vw 357vh #fff, 31vw 389vh #fff, 32vw 586vh #fff, 33vw 190vh #fff, 34vw 480vh #fff, 35vw 320vh #fff,
			36vw 567vh #fff, 37vw 56vh #fff, 38vw 439vh #fff, 39vw 192vh #fff, 40vw 691vh #fff, 41vw 681vh #fff, 42vw 111vh #fff, 43vw 238vh #fff, 44vw 198vh #fff,
			45vw 689vh #fff, 46vw 511vh #fff, 47vw 206vh #fff, 48vw 448vh #fff, 49vw 69vh #fff, 50vw 427vh #fff, 51vw 528vh #fff, 52vw 468vh #fff, 53vw 351vh #fff,
			54vw 108vh #fff, 55vw 675vh #fff, 56vw 301vh #fff, 57vw 86vh #fff, 58vw 684vh #fff, 59vw 662vh #fff, 60vw 139vh #fff, 61vw 181vh #fff, 62vw 590vh #fff,
			63vw 404vh #fff, 64vw 502vh #fff, 65vw 53vh #fff, 66vw 296vh #fff, 67vw 362vh #fff, 68vw 596vh #fff, 69vw 343vh #fff, 70vw 515vh #fff, 71vw 443vh #fff,
			72vw 3vh #fff, 73vw 133vh #fff, 74vw 588vh #fff, 75vw 591vh #fff, 76vw 20vh #fff, 77vw 419vh #fff, 78vw 215vh #fff, 79vw 10vh #fff, 80vw 525vh #fff,
			81vw 235vh #fff, 82vw 540vh #fff, 83vw 614vh #fff, 84vw 432vh #fff, 85vw 407vh #fff, 86vw 460vh #fff, 87vw 18vh #fff, 88vw 254vh #fff, 89vw 621vh #fff,
			90vw 445vh #fff, 91vw 165vh #fff, 92vw 489vh #fff, 93vw 195vh #fff, 94vw 183vh #fff, 95vw 304vh #fff, 96vw 575vh #fff, 97vw 492vh #fff, 98vw 286vh #fff,
			99vw 422vh #fff, 100vw 342vh #fff, 1vw 592vh #fff, 2vw 135vh #fff, 3vw 454vh #fff, 4vw 213vh #fff, 5vw 93vh #fff, 6vw 259vh #fff, 7vw 170vh #fff,
			8vw 233vh #fff, 9vw 680vh #fff, 10vw 344vh #fff, 11vw 150vh #fff, 12vw 188vh #fff, 13vw 38vh #fff, 14vw 285vh #fff, 15vw 564vh #fff, 16vw 384vh #fff,
			17vw 290vh #fff, 18vw 392vh #fff, 19vw 697vh #fff, 20vw 182vh #fff, 21vw 370vh #fff, 22vw 634vh #fff, 23vw 253vh #fff, 24vw 418vh #fff, 25vw 555vh #fff,
			26vw 12vh #fff, 27vw 444vh #fff, 28vw 244vh #fff, 29vw 203vh #fff, 30vw 413vh #fff, 31vw 560vh #fff, 32vw 668vh #fff, 33vw 569vh #fff, 34vw 14vh #fff,
			35vw 428vh #fff, 36vw 87vh #fff, 37vw 288vh #fff, 38vw 57vh #fff, 39vw 210vh #fff, 40vw 495vh #fff, 41vw 39vh #fff, 42vw 457vh #fff, 43vw 156vh #fff,
			44vw 68vh #fff, 45vw 205vh #fff, 46vw 549vh #fff, 47vw 523vh #fff, 48vw 499vh #fff, 49vw 530vh #fff, 50vw 554vh #fff, 51vw 414vh #fff, 52vw 683vh #fff,
			53vw 268vh #fff, 54vw 25vh #fff, 55vw 536vh #fff, 56vw 207vh #fff, 57vw 119vh #fff, 58vw 561vh #fff, 59vw 537vh #fff, 60vw 280vh #fff, 61vw 623vh #fff,
			62vw 677vh #fff, 63vw 21vh #fff, 64vw 474vh #fff, 65vw 276vh #fff, 66vw 412vh #fff, 67vw 534vh #fff, 68vw 101vh #fff, 69vw 128vh #fff, 70vw 305vh #fff,
			71vw 332vh #fff, 72vw 608vh #fff, 73vw 498vh #fff, 74vw 113vh #fff, 75vw 563vh #fff, 76vw 4vh #fff, 77vw 441vh #fff, 78vw 477vh #fff, 79vw 15vh #fff,
			80vw 520vh #fff, 81vw 186vh #fff, 82vw 469vh #fff, 83vw 158vh #fff, 84vw 405vh #fff, 85vw 45vh #fff, 86vw 380vh #fff, 87vw 236vh #fff, 88vw 346vh #fff,
			89vw 141vh #fff, 90vw 582vh #fff, 91vw 250vh #fff, 92vw 176vh #fff, 93vw 171vh #fff, 94vw 603vh #fff, 95vw 400vh #fff, 96vw 6vh #fff, 97vw 211vh #fff,
			98vw 571vh #fff, 99vw 64vh #fff, 100vw 327vh #fff, 1vw 524vh #fff, 2vw 120vh #fff, 3vw 699vh #fff, 4vw 505vh #fff, 5vw 5vh #fff, 6vw 550vh #fff,
			7vw 452vh #fff, 8vw 372vh #fff, 9vw 497vh #fff, 10vw 494vh #fff, 11vw 548vh #fff, 12vw 136vh #fff, 13vw 293vh #fff, 14vw 690vh #fff, 15vw 376vh #fff,
			16vw 154vh #fff, 17vw 449vh #fff, 18vw 390vh #fff, 19vw 83vh #fff, 20vw 685vh #fff, 21vw 629vh #fff, 22vw 55vh #fff, 23vw 34vh #fff, 24vw 78vh #fff,
			25vw 79vh #fff, 26vw 522vh #fff, 27vw 189vh #fff, 28vw 199vh #fff, 29vw 232vh #fff, 30vw 114vh #fff, 31vw 479vh #fff, 32vw 265vh #fff, 33vw 605vh #fff,
			34vw 267vh #fff, 35vw 337vh #fff, 36vw 484vh #fff, 37vw 143vh #fff, 38vw 504vh #fff, 39vw 628vh #fff, 40vw 595vh #fff, 41vw 281vh #fff, 42vw 294vh #fff,
			43vw 694vh #fff, 44vw 163vh #fff, 45vw 160vh #fff, 46vw 369vh #fff, 47vw 512vh #fff, 48vw 669vh #fff, 49vw 214vh #fff, 50vw 500vh #fff, 51vw 611vh #fff,
			52vw 672vh #fff, 53vw 568vh #fff, 54vw 686vh #fff, 55vw 140vh #fff, 56vw 671vh #fff, 57vw 129vh #fff, 58vw 333vh #fff, 59vw 319vh #fff, 60vw 664vh #fff,
			61vw 483vh #fff, 62vw 245vh #fff, 63vw 90vh #fff, 64vw 173vh #fff, 65vw 643vh #fff, 66vw 271vh #fff, 67vw 698vh #fff, 68vw 312vh #fff, 69vw 580vh #fff,
			70vw 583vh #fff, 71vw 355vh #fff, 72vw 85vh #fff, 73vw 175vh #fff, 74vw 353vh #fff, 75vw 491vh #fff, 76vw 601vh #fff, 77vw 531vh #fff, 78vw 574vh #fff,
			79vw 508vh #fff, 80vw 58vh #fff, 81vw 16vh #fff, 82vw 326vh #fff, 83vw 263vh #fff, 84vw 99vh #fff, 85vw 673vh #fff, 86vw 408vh #fff, 87vw 241vh #fff,
			88vw 138vh #fff, 89vw 358vh #fff, 90vw 676vh #fff, 91vw 196vh #fff, 92vw 692vh #fff, 93vw 486vh #fff, 94vw 654vh #fff, 95vw 70vh #fff, 96vw 348vh #fff,
			97vw 19vh #fff, 98vw 594vh #fff, 99vw 455vh #fff, 100vw 518vh #fff, 1vw 650vh #fff, 2vw 273vh #fff, 3vw 364vh #fff, 4vw 649vh #fff, 5vw 473vh #fff,
			6vw 682vh #fff, 7vw 216vh #fff, 8vw 612vh #fff, 9vw 317vh #fff, 10vw 657vh #fff, 11vw 27vh #fff, 12vw 88vh #fff, 13vw 145vh #fff, 14vw 423vh #fff,
			15vw 228vh #fff, 16vw 318vh #fff, 17vw 570vh #fff, 18vw 475vh #fff, 19vw 242vh #fff, 20vw 167vh #fff, 21vw 314vh #fff, 22vw 323vh #fff, 23vw 453vh #fff,
			24vw 124vh #fff, 25vw 224vh #fff, 26vw 169vh #fff, 27vw 302vh #fff, 28vw 521vh #fff, 29vw 49vh #fff, 30vw 152vh #fff, 31vw 13vh #fff, 32vw 303vh #fff,
			33vw 148vh #fff, 34vw 648vh #fff, 35vw 307vh #fff, 36vw 100vh #fff, 37vw 382vh #fff, 38vw 670vh #fff, 39vw 581vh #fff, 40vw 606vh #fff, 41vw 565vh #fff,
			42vw 386vh #fff, 43vw 185vh #fff, 44vw 184vh #fff, 45vw 52vh #fff, 46vw 576vh #fff, 47vw 50vh #fff, 48vw 653vh #fff, 49vw 194vh #fff, 50vw 75vh #fff,
			51vw 476vh #fff, 52vw 63vh #fff, 53vw 48vh #fff, 54vw 368vh #fff, 55vw 349vh #fff, 56vw 345vh #fff, 57vw 666vh #fff, 58vw 647vh #fff, 59vw 547vh #fff,
			60vw 220vh #fff, 61vw 230vh #fff, 62vw 625vh #fff, 63vw 557vh #fff, 64vw 37vh #fff, 65vw 31vh #fff, 66vw 610vh #fff, 67vw 624vh #fff, 68vw 272vh #fff,
			69vw 402vh #fff, 70vw 161vh #fff, 71vw 437vh #fff, 72vw 416vh #fff, 73vw 106vh #fff, 74vw 604vh #fff, 75vw 249vh #fff, 76vw 695vh #fff, 77vw 132vh #fff,
			78vw 74vh #fff, 79vw 248vh #fff, 80vw 347vh #fff, 81vw 130vh #fff, 82vw 131vh #fff, 83vw 218vh #fff, 84vw 517vh #fff, 85vw 81vh #fff, 86vw 137vh #fff,
			87vw 636vh #fff, 88vw 387vh #fff, 89vw 28vh #fff, 90vw 274vh #fff, 91vw 519vh #fff, 92vw 688vh #fff, 93vw 541vh #fff, 94vw 289vh #fff, 95vw 123vh #fff,
			96vw 558vh #fff, 97vw 516vh #fff, 98vw 471vh #fff, 99vw 438vh #fff, 100vw 96vh #fff, 1vw 84vh #fff, 2vw 147vh #fff, 3vw 526vh #fff, 4vw 578vh #fff,
			5vw 679vh #fff, 6vw 260vh #fff, 7vw 424vh #fff, 8vw 527vh #fff, 9vw 306vh #fff, 10vw 617vh #fff, 11vw 488vh #fff, 12vw 217vh #fff, 13vw 651vh #fff,
			14vw 80vh #fff, 15vw 72vh #fff, 16vw 652vh #fff, 17vw 116vh #fff, 18vw 1vh #fff, 19vw 544vh #fff, 20vw 573vh #fff, 21vw 284vh #fff, 22vw 251vh #fff,
			23vw 618vh #fff, 24vw 315vh #fff, 25vw 373vh #fff, 26vw 121vh #fff, 27vw 118vh #fff, 28vw 212vh #fff, 29vw 632vh #fff, 30vw 247vh #fff, 31vw 532vh #fff,
			32vw 626vh #fff, 33vw 122vh #fff, 34vw 222vh #fff, 35vw 237vh #fff, 36vw 179vh #fff, 37vw 67vh #fff, 38vw 103vh #fff, 39vw 264vh #fff, 40vw 693vh #fff,
			41vw 627vh #fff, 42vw 379vh #fff, 43vw 335vh #fff, 44vw 316vh #fff, 45vw 22vh #fff, 46vw 667vh #fff, 47vw 174vh #fff, 48vw 633vh #fff, 49vw 411vh #fff,
			50vw 472vh #fff, 51vw 425vh #fff, 52vw 383vh #fff, 53vw 324vh #fff, 54vw 266vh #fff, 55vw 282vh #fff, 56vw 270vh #fff, 57vw 287vh #fff, 58vw 11vh #fff,
			59vw 593vh #fff, 60vw 89vh #fff, 61vw 356vh #fff, 62vw 401vh #fff, 63vw 331vh #fff, 64vw 391vh #fff, 65vw 43vh #fff, 66vw 361vh #fff, 67vw 434vh #fff,
			68vw 204vh #fff, 69vw 77vh #fff, 70vw 467vh #fff, 71vw 615vh #fff, 72vw 600vh #fff, 73vw 166vh #fff, 74vw 155vh #fff, 75vw 399vh #fff, 76vw 598vh #fff,
			77vw 485vh #fff, 78vw 350vh #fff, 79vw 187vh #fff, 80vw 197vh #fff, 81vw 496vh #fff, 82vw 297vh #fff, 83vw 674vh #fff, 84vw 478vh #fff, 85vw 322vh #fff,
			86vw 178vh #fff, 87vw 539vh #fff, 88vw 298vh #fff, 89vw 514vh #fff, 90vw 584vh #fff, 91vw 162vh #fff, 92vw 229vh #fff, 93vw 41vh #fff, 94vw 597vh #fff,
			95vw 227vh #fff, 96vw 406vh #fff, 97vw 239vh #fff, 98vw 309vh #fff, 99vw 177vh #fff, 100vw 261vh #fff, 1vw 2vh #fff, 2vw 47vh #fff, 3vw 360vh #fff,
			4vw 509vh #fff, 5vw 334vh #fff, 6vw 29vh #fff, 7vw 339vh #fff, 8vw 127vh #fff, 9vw 417vh #fff, 10vw 269vh #fff, 11vw 553vh #fff, 12vw 572vh #fff,
			13vw 257vh #fff, 14vw 546vh #fff, 15vw 97vh #fff, 16vw 9vh #fff, 17vw 234vh #fff, 18vw 442vh #fff, 19vw 646vh #fff, 20vw 599vh #fff, 21vw 616vh #fff,
			22vw 180vh #fff, 23vw 35vh #fff, 24vw 363vh #fff, 25vw 366vh #fff, 26vw 374vh #fff, 27vw 71vh #fff, 28vw 33vh #fff, 29vw 330vh #fff, 30vw 329vh #fff,
			31vw 291vh #fff, 32vw 159vh #fff, 33vw 562vh #fff, 34vw 513vh #fff, 35vw 696vh #fff, 36vw 393vh #fff, 37vw 431vh #fff, 38vw 415vh #fff, 39vw 255vh #fff,
			40vw 219vh #fff, 41vw 381vh #fff, 42vw 7vh #fff, 43vw 275vh #fff, 44vw 655vh #fff, 45vw 59vh #fff, 46vw 375vh #fff, 47vw 365vh #fff, 48vw 481vh #fff,
			49vw 202vh #fff, 50vw 420vh #fff, 51vw 464vh #fff, 52vw 115vh #fff, 53vw 538vh #fff, 54vw 642vh #fff, 55vw 533vh #fff, 56vw 529vh #fff, 57vw 341vh #fff,
			58vw 687vh #fff, 59vw 429vh #fff, 60vw 637vh #fff, 61vw 463vh #fff, 62vw 208vh #fff, 63vw 256vh #fff, 64vw 246vh #fff, 65vw 283vh #fff, 66vw 447vh #fff,
			67vw 95vh #fff, 68vw 482vh #fff, 69vw 105vh #fff, 70vw 589vh #fff, 71vw 397vh #fff, 72vw 579vh #fff, 73vw 456vh #fff, 74vw 462vh #fff, 75vw 262vh #fff,
			76vw 385vh #fff, 77vw 92vh #fff, 78vw 151vh #fff, 79vw 430vh #fff, 80vw 635vh #fff, 81vw 223vh #fff, 82vw 164vh #fff, 83vw 490vh #fff, 84vw 613vh #fff,
			85vw 388vh #fff, 86vw 142vh #fff, 87vw 552vh #fff, 88vw 338vh #fff, 89vw 23vh #fff, 90vw 510vh #fff, 91vw 17vh #fff, 92vw 645vh #fff, 93vw 117vh #fff,
			94vw 398vh #fff, 95vw 328vh #fff, 96vw 107vh #fff, 97vw 149vh #fff, 98vw 354vh #fff, 99vw 40vh #fff, 100vw 51vh #fff;
	}

	#stars2 {
		width: 2px;
		height: 2px;
		background: transparent;
		box-shadow: 1vw 584vh #fff, 2vw 512vh #fff, 3vw 85vh #fff, 4vw 334vh #fff, 5vw 103vh #fff, 6vw 6vh #fff, 7vw 374vh #fff, 8vw 458vh #fff, 9vw 218vh #fff,
			10vw 79vh #fff, 11vw 177vh #fff, 12vw 515vh #fff, 13vw 120vh #fff, 14vw 616vh #fff, 15vw 525vh #fff, 16vw 685vh #fff, 17vw 238vh #fff, 18vw 58vh #fff,
			19vw 609vh #fff, 20vw 39vh #fff, 21vw 153vh #fff, 22vw 176vh #fff, 23vw 369vh #fff, 24vw 224vh #fff, 25vw 264vh #fff, 26vw 128vh #fff, 27vw 639vh #fff,
			28vw 479vh #fff, 29vw 674vh #fff, 30vw 642vh #fff, 31vw 500vh #fff, 32vw 127vh #fff, 33vw 165vh #fff, 34vw 318vh #fff, 35vw 100vh #fff, 36vw 493vh #fff,
			37vw 510vh #fff, 38vw 618vh #fff, 39vw 496vh #fff, 40vw 582vh #fff, 41vw 355vh #fff, 42vw 579vh #fff, 43vw 569vh #fff, 44vw 289vh #fff, 45vw 40vh #fff,
			46vw 444vh #fff, 47vw 368vh #fff, 48vw 615vh #fff, 49vw 188vh #fff, 50vw 162vh #fff, 51vw 409vh #fff, 52vw 191vh #fff, 53vw 643vh #fff, 54vw 1vh #fff,
			55vw 370vh #fff, 56vw 623vh #fff, 57vw 169vh #fff, 58vw 536vh #fff, 59vw 284vh #fff, 60vw 242vh #fff, 61vw 505vh #fff, 62vw 254vh #fff, 63vw 136vh #fff,
			64vw 239vh #fff, 65vw 256vh #fff, 66vw 15vh #fff, 67vw 574vh #fff, 68vw 456vh #fff, 69vw 696vh #fff, 70vw 57vh #fff, 71vw 357vh #fff, 72vw 401vh #fff,
			73vw 316vh #fff, 74vw 139vh #fff, 75vw 457vh #fff, 76vw 606vh #fff, 77vw 449vh #fff, 78vw 467vh #fff, 79vw 375vh #fff, 80vw 322vh #fff, 81vw 382vh #fff,
			82vw 423vh #fff, 83vw 695vh #fff, 84vw 12vh #fff, 85vw 394vh #fff, 86vw 193vh #fff, 87vw 387vh #fff, 88vw 336vh #fff, 89vw 105vh #fff, 90vw 551vh #fff,
			91vw 313vh #fff, 92vw 115vh #fff, 93vw 593vh #fff, 94vw 421vh #fff, 95vw 43vh #fff, 96vw 597vh #fff, 97vw 414vh #fff, 98vw 124vh #fff, 99vw 660vh #fff,
			100vw 676vh #fff, 1vw 222vh #fff, 2vw 328vh #fff, 3vw 555vh #fff, 4vw 32vh #fff, 5vw 11vh #fff, 6vw 568vh #fff, 7vw 365vh #fff, 8vw 41vh #fff,
			9vw 485vh #fff, 10vw 580vh #fff, 11vw 366vh #fff, 12vw 543vh #fff, 13vw 202vh #fff, 14vw 588vh #fff, 15vw 294vh #fff, 16vw 155vh #fff, 17vw 504vh #fff,
			18vw 232vh #fff, 19vw 324vh #fff, 20vw 698vh #fff, 21vw 55vh #fff, 22vw 501vh #fff, 23vw 632vh #fff, 24vw 225vh #fff, 25vw 386vh #fff, 26vw 2vh #fff,
			27vw 484vh #fff, 28vw 689vh #fff, 29vw 589vh #fff, 30vw 358vh #fff, 31vw 5vh #fff, 32vw 119vh #fff, 33vw 288vh #fff, 34vw 404vh #fff, 35vw 178vh #fff,
			36vw 667vh #fff, 37vw 670vh #fff, 38vw 436vh #fff, 39vw 564vh #fff, 40vw 240vh #fff, 41vw 596vh #fff, 42vw 215vh #fff, 43vw 480vh #fff, 44vw 143vh #fff,
			45vw 133vh #fff, 46vw 659vh #fff, 47vw 617vh #fff, 48vw 65vh #fff, 49vw 592vh #fff, 50vw 412vh #fff, 51vw 81vh #fff, 52vw 552vh #fff, 53vw 194vh #fff,
			54vw 547vh #fff, 55vw 198vh #fff, 56vw 428vh #fff, 57vw 303vh #fff, 58vw 419vh #fff, 59vw 441vh #fff, 60vw 522vh #fff, 61vw 267vh #fff, 62vw 62vh #fff,
			63vw 626vh #fff, 64vw 390vh #fff, 65vw 528vh #fff, 66vw 553vh #fff, 67vw 649vh #fff, 68vw 427vh #fff, 69vw 516vh #fff, 70vw 7vh #fff, 71vw 678vh #fff,
			72vw 338vh #fff, 73vw 149vh #fff, 74vw 498vh #fff, 75vw 123vh #fff, 76vw 227vh #fff, 77vw 34vh #fff, 78vw 399vh #fff, 79vw 546vh #fff, 80vw 186vh #fff,
			81vw 299vh #fff, 82vw 562vh #fff, 83vw 348vh #fff, 84vw 331vh #fff, 85vw 468vh #fff, 86vw 489vh #fff, 87vw 168vh #fff, 88vw 631vh #fff, 89vw 693vh #fff,
			90vw 98vh #fff, 91vw 665vh #fff, 92vw 622vh #fff, 93vw 339vh #fff, 94vw 92vh #fff, 95vw 97vh #fff, 96vw 602vh #fff, 97vw 545vh #fff, 98vw 611vh #fff,
			99vw 656vh #fff, 100vw 662vh #fff, 1vw 274vh #fff, 2vw 116vh #fff, 3vw 246vh #fff, 4vw 435vh #fff, 5vw 33vh #fff, 6vw 205vh #fff, 7vw 305vh #fff,
			8vw 278vh #fff, 9vw 290vh #fff, 10vw 655vh #fff, 11vw 383vh #fff, 12vw 195vh #fff, 13vw 333vh #fff, 14vw 349vh #fff, 15vw 475vh #fff, 16vw 429vh #fff,
			17vw 72vh #fff, 18vw 51vh #fff, 19vw 541vh #fff, 20vw 502vh #fff, 21vw 310vh #fff, 22vw 63vh #fff, 23vw 624vh #fff, 24vw 131vh #fff, 25vw 145vh #fff,
			26vw 406vh #fff, 27vw 637vh #fff, 28vw 471vh #fff, 29vw 295vh #fff, 30vw 326vh #fff, 31vw 235vh #fff, 32vw 59vh #fff, 33vw 634vh #fff, 34vw 16vh #fff,
			35vw 203vh #fff, 36vw 78vh #fff, 37vw 681vh #fff, 38vw 400vh #fff, 39vw 478vh #fff, 40vw 135vh #fff, 41vw 548vh #fff, 42vw 445vh #fff, 43vw 325vh #fff,
			44vw 179vh #fff, 45vw 384vh #fff, 46vw 280vh #fff, 47vw 17vh #fff, 48vw 74vh #fff, 49vw 595vh #fff, 50vw 27vh #fff, 51vw 230vh #fff, 52vw 84vh #fff,
			53vw 523vh #fff, 54vw 226vh #fff, 55vw 113vh #fff, 56vw 373vh #fff, 57vw 137vh #fff, 58vw 395vh #fff, 59vw 272vh #fff, 60vw 417vh #fff, 61vw 308vh #fff,
			62vw 410vh #fff, 63vw 335vh #fff, 64vw 420vh #fff, 65vw 361vh #fff, 66vw 438vh #fff, 67vw 524vh #fff, 68vw 45vh #fff, 69vw 121vh #fff, 70vw 132vh #fff,
			71vw 245vh #fff, 72vw 99vh #fff, 73vw 61vh #fff, 74vw 60vh #fff, 75vw 315vh #fff, 76vw 526vh #fff, 77vw 69vh #fff, 78vw 411vh #fff, 79vw 86vh #fff,
			80vw 258vh #fff, 81vw 640vh #fff, 82vw 668vh #fff, 83vw 565vh #fff, 84vw 293vh #fff, 85vw 581vh #fff, 86vw 646vh #fff, 87vw 250vh #fff, 88vw 154vh #fff,
			89vw 269vh #fff, 90vw 54vh #fff, 91vw 161vh #fff, 92vw 20vh #fff, 93vw 487vh #fff, 94vw 544vh #fff, 95vw 42vh #fff, 96vw 302vh #fff, 97vw 483vh #fff,
			98vw 76vh #fff, 99vw 252vh #fff, 100vw 453vh #fff, 1vw 122vh #fff, 2vw 273vh #fff, 3vw 360vh #fff, 4vw 182vh #fff, 5vw 219vh #fff, 6vw 236vh #fff,
			7vw 190vh #fff, 8vw 367vh #fff, 9vw 607vh #fff, 10vw 636vh #fff, 11vw 265vh #fff, 12vw 691vh #fff, 13vw 434vh #fff, 14vw 389vh #fff, 15vw 620vh #fff,
			16vw 472vh #fff, 17vw 102vh #fff, 18vw 243vh #fff, 19vw 261vh #fff, 20vw 164vh #fff, 21vw 554vh #fff, 22vw 279vh #fff, 23vw 532vh #fff, 24vw 80vh #fff,
			25vw 576vh #fff, 26vw 657vh #fff, 27vw 673vh #fff, 28vw 52vh #fff, 29vw 507vh #fff, 30vw 405vh #fff, 31vw 376vh #fff, 32vw 260vh #fff, 33vw 561vh #fff,
			34vw 234vh #fff, 35vw 36vh #fff, 36vw 393vh #fff, 37vw 263vh #fff, 38vw 430vh #fff, 39vw 68vh #fff, 40vw 53vh #fff, 41vw 323vh #fff, 42vw 173vh #fff,
			43vw 281vh #fff, 44vw 381vh #fff, 45vw 424vh #fff, 46vw 654vh #fff, 47vw 465vh #fff, 48vw 440vh #fff, 49vw 539vh #fff, 50vw 470vh #fff, 51vw 287vh #fff,
			52vw 398vh #fff, 53vw 413vh #fff, 54vw 520vh #fff, 55vw 442vh #fff, 56vw 270vh #fff, 57vw 151vh #fff, 58vw 687vh #fff, 59vw 19vh #fff, 60vw 18vh #fff,
			61vw 46vh #fff, 62vw 111vh #fff, 63vw 572vh #fff, 64vw 282vh #fff, 65vw 604vh #fff, 66vw 585vh #fff, 67vw 112vh #fff, 68vw 450vh #fff, 69vw 690vh #fff,
			70vw 134vh #fff, 71vw 196vh #fff, 72vw 160vh #fff, 73vw 353vh #fff, 74vw 354vh #fff, 75vw 519vh #fff, 76vw 422vh #fff, 77vw 144vh #fff, 78vw 329vh #fff,
			79vw 259vh #fff, 80vw 130vh #fff, 81vw 95vh #fff, 82vw 209vh #fff, 83vw 534vh #fff, 84vw 244vh #fff, 85vw 197vh #fff, 86vw 8vh #fff, 87vw 29vh #fff,
			88vw 653vh #fff, 89vw 359vh #fff, 90vw 181vh #fff, 91vw 309vh #fff, 92vw 416vh #fff, 93vw 350vh #fff, 94vw 699vh #fff, 95vw 563vh #fff, 96vw 652vh #fff,
			97vw 317vh #fff, 98vw 216vh #fff, 99vw 171vh #fff, 100vw 214vh #fff, 1vw 207vh #fff, 2vw 567vh #fff, 3vw 473vh #fff, 4vw 21vh #fff, 5vw 301vh #fff,
			6vw 262vh #fff, 7vw 185vh #fff, 8vw 694vh #fff, 9vw 204vh #fff, 10vw 47vh #fff, 11vw 586vh #fff, 12vw 148vh #fff, 13vw 463vh #fff, 14vw 378vh #fff,
			15vw 220vh #fff, 16vw 22vh #fff, 17vw 184vh #fff, 18vw 298vh #fff, 19vw 341vh #fff, 20vw 71vh #fff, 21vw 530vh #fff, 22vw 537vh #fff, 23vw 460vh #fff,
			24vw 343vh #fff, 25vw 549vh #fff, 26vw 217vh #fff, 27vw 101vh #fff, 28vw 363vh #fff, 29vw 93vh #fff, 30vw 605vh #fff, 31vw 10vh #fff, 32vw 44vh #fff,
			33vw 352vh #fff, 34vw 150vh #fff, 35vw 540vh #fff, 36vw 446vh #fff, 37vw 117vh #fff, 38vw 4vh #fff, 39vw 141vh #fff, 40vw 675vh #fff, 41vw 142vh #fff,
			42vw 286vh #fff, 43vw 557vh #fff, 44vw 379vh #fff, 45vw 189vh #fff, 46vw 271vh #fff, 47vw 312vh #fff, 48vw 388vh #fff, 49vw 577vh #fff, 50vw 426vh #fff,
			51vw 556vh #fff, 52vw 486vh #fff, 53vw 346vh #fff, 54vw 277vh #fff, 55vw 651vh #fff, 56vw 451vh #fff, 57vw 31vh #fff, 58vw 330vh #fff, 59vw 433vh #fff,
			60vw 627vh #fff, 61vw 628vh #fff, 62vw 601vh #fff, 63vw 49vh #fff, 64vw 56vh #fff, 65vw 362vh #fff, 66vw 497vh #fff, 67vw 645vh #fff, 68vw 664vh #fff,
			69vw 249vh #fff, 70vw 447vh #fff, 71vw 481vh #fff, 72vw 83vh #fff, 73vw 671vh #fff, 74vw 247vh #fff, 75vw 23vh #fff, 76vw 146vh #fff, 77vw 38vh #fff,
			78vw 187vh #fff, 79vw 311vh #fff, 80vw 630vh #fff, 81vw 491vh #fff, 82vw 152vh #fff, 83vw 641vh #fff, 84vw 174vh #fff, 85vw 385vh #fff, 86vw 418vh #fff,
			87vw 364vh #fff, 88vw 82vh #fff, 89vw 600vh #fff, 90vw 156vh #fff, 91vw 647vh #fff, 92vw 635vh #fff, 93vw 89vh #fff, 94vw 13vh #fff, 95vw 88vh #fff,
			96vw 680vh #fff, 97vw 633vh #fff, 98vw 570vh #fff, 99vw 138vh #fff, 100vw 529vh #fff, 1vw 233vh #fff, 2vw 603vh #fff, 3vw 296vh #fff, 4vw 229vh #fff,
			5vw 459vh #fff, 6vw 608vh #fff, 7vw 661vh #fff, 8vw 461vh #fff, 9vw 573vh #fff, 10vw 499vh #fff, 11vw 559vh #fff, 12vw 495vh #fff, 13vw 300vh #fff,
			14vw 255vh #fff, 15vw 342vh #fff, 16vw 448vh #fff, 17vw 506vh #fff, 18vw 200vh #fff, 19vw 566vh #fff, 20vw 672vh #fff, 21vw 140vh #fff, 22vw 402vh #fff,
			23vw 550vh #fff, 24vw 167vh #fff, 25vw 241vh #fff, 26vw 594vh #fff, 27vw 462vh #fff, 28vw 48vh #fff, 29vw 275vh #fff, 30vw 106vh #fff, 31vw 35vh #fff,
			32vw 67vh #fff, 33vw 415vh #fff, 34vw 517vh #fff, 35vw 437vh #fff, 36vw 408vh #fff, 37vw 443vh #fff, 38vw 37vh #fff, 39vw 107vh #fff, 40vw 658vh #fff,
			41vw 587vh #fff, 42vw 221vh #fff, 43vw 533vh #fff, 44vw 91vh #fff, 45vw 535vh #fff, 46vw 347vh #fff, 47vw 211vh #fff, 48vw 648vh #fff, 49vw 614vh #fff,
			50vw 613vh #fff, 51vw 297vh #fff, 52vw 285vh #fff, 53vw 431vh #fff, 54vw 118vh #fff, 55vw 397vh #fff, 56vw 683vh #fff, 57vw 371vh #fff, 58vw 469vh #fff,
			59vw 344vh #fff, 60vw 307vh #fff, 61vw 268vh #fff, 62vw 199vh #fff, 63vw 337vh #fff, 64vw 314vh #fff, 65vw 327vh #fff, 66vw 206vh #fff, 67vw 439vh #fff,
			68vw 223vh #fff, 69vw 351vh #fff, 70vw 518vh #fff, 71vw 30vh #fff, 72vw 77vh #fff, 73vw 292vh #fff, 74vw 291vh #fff, 75vw 213vh #fff, 76vw 625vh #fff,
			77vw 686vh #fff, 78vw 514vh #fff, 79vw 591vh #fff, 80vw 231vh #fff, 81vw 644vh #fff, 82vw 508vh #fff, 83vw 25vh #fff, 84vw 575vh #fff, 85vw 542vh #fff,
			86vw 158vh #fff, 87vw 237vh #fff, 88vw 521vh #fff, 89vw 125vh #fff, 90vw 192vh #fff, 91vw 14vh #fff, 92vw 66vh #fff, 93vw 492vh #fff, 94vw 629vh #fff,
			95vw 396vh #fff, 96vw 474vh #fff, 97vw 488vh #fff, 98vw 682vh #fff, 99vw 319vh #fff, 100vw 114vh #fff, 1vw 590vh #fff, 2vw 679vh #fff, 3vw 159vh #fff,
			4vw 109vh #fff, 5vw 208vh #fff, 6vw 503vh #fff, 7vw 454vh #fff, 8vw 509vh #fff, 9vw 94vh #fff, 10vw 126vh #fff, 11vw 304vh #fff, 12vw 24vh #fff,
			13vw 129vh #fff, 14vw 3vh #fff, 15vw 531vh #fff, 16vw 455vh #fff, 17vw 466vh #fff, 18vw 464vh #fff, 19vw 180vh #fff, 20vw 210vh #fff, 21vw 332vh #fff,
			22vw 432vh #fff, 23vw 599vh #fff, 24vw 583vh #fff, 25vw 276vh #fff, 26vw 28vh #fff, 27vw 407vh #fff, 28vw 175vh #fff, 29vw 538vh #fff, 30vw 513vh #fff,
			31vw 90vh #fff, 32vw 490vh #fff, 33vw 257vh #fff, 34vw 476vh #fff, 35vw 73vh #fff, 36vw 266vh #fff, 37vw 560vh #fff, 38vw 50vh #fff, 39vw 700vh #fff,
			40vw 321vh #fff, 41vw 669vh #fff, 42vw 612vh #fff, 43vw 251vh #fff, 44vw 283vh #fff, 45vw 320vh #fff, 46vw 228vh #fff, 47vw 619vh #fff, 48vw 610vh #fff,
			49vw 96vh #fff, 50vw 663vh #fff, 51vw 70vh #fff, 52vw 527vh #fff, 53vw 104vh #fff, 54vw 578vh #fff, 55vw 201vh #fff, 56vw 391vh #fff, 57vw 172vh #fff,
			58vw 697vh #fff, 59vw 392vh #fff, 60vw 9vh #fff, 61vw 163vh #fff, 62vw 108vh #fff, 63vw 650vh #fff, 64vw 558vh #fff, 65vw 692vh #fff, 66vw 345vh #fff,
			67vw 212vh #fff, 68vw 638vh #fff, 69vw 340vh #fff, 70vw 666vh #fff, 71vw 598vh #fff, 72vw 377vh #fff, 73vw 380vh #fff, 74vw 684vh #fff, 75vw 157vh #fff,
			76vw 170vh #fff, 77vw 494vh #fff, 78vw 621vh #fff, 79vw 183vh #fff, 80vw 403vh #fff, 81vw 482vh #fff, 82vw 688vh #fff, 83vw 26vh #fff, 84vw 253vh #fff,
			85vw 306vh #fff, 86vw 110vh #fff, 87vw 571vh #fff, 88vw 677vh #fff, 89vw 147vh #fff, 90vw 87vh #fff, 91vw 75vh #fff, 92vw 356vh #fff, 93vw 166vh #fff,
			94vw 372vh #fff, 95vw 477vh #fff, 96vw 248vh #fff, 97vw 511vh #fff, 98vw 452vh #fff, 99vw 64vh #fff, 100vw 425vh #fff;
		animation: animStar 150s linear infinite;
	}

	#stars2:after {
		content: " ";
		position: absolute;
		width: 2px;
		height: 2px;
		background: transparent;
		box-shadow: 1vw 584vh #fff, 2vw 512vh #fff, 3vw 85vh #fff, 4vw 334vh #fff, 5vw 103vh #fff, 6vw 6vh #fff, 7vw 374vh #fff, 8vw 458vh #fff, 9vw 218vh #fff,
			10vw 79vh #fff, 11vw 177vh #fff, 12vw 515vh #fff, 13vw 120vh #fff, 14vw 616vh #fff, 15vw 525vh #fff, 16vw 685vh #fff, 17vw 238vh #fff, 18vw 58vh #fff,
			19vw 609vh #fff, 20vw 39vh #fff, 21vw 153vh #fff, 22vw 176vh #fff, 23vw 369vh #fff, 24vw 224vh #fff, 25vw 264vh #fff, 26vw 128vh #fff, 27vw 639vh #fff,
			28vw 479vh #fff, 29vw 674vh #fff, 30vw 642vh #fff, 31vw 500vh #fff, 32vw 127vh #fff, 33vw 165vh #fff, 34vw 318vh #fff, 35vw 100vh #fff, 36vw 493vh #fff,
			37vw 510vh #fff, 38vw 618vh #fff, 39vw 496vh #fff, 40vw 582vh #fff, 41vw 355vh #fff, 42vw 579vh #fff, 43vw 569vh #fff, 44vw 289vh #fff, 45vw 40vh #fff,
			46vw 444vh #fff, 47vw 368vh #fff, 48vw 615vh #fff, 49vw 188vh #fff, 50vw 162vh #fff, 51vw 409vh #fff, 52vw 191vh #fff, 53vw 643vh #fff, 54vw 1vh #fff,
			55vw 370vh #fff, 56vw 623vh #fff, 57vw 169vh #fff, 58vw 536vh #fff, 59vw 284vh #fff, 60vw 242vh #fff, 61vw 505vh #fff, 62vw 254vh #fff, 63vw 136vh #fff,
			64vw 239vh #fff, 65vw 256vh #fff, 66vw 15vh #fff, 67vw 574vh #fff, 68vw 456vh #fff, 69vw 696vh #fff, 70vw 57vh #fff, 71vw 357vh #fff, 72vw 401vh #fff,
			73vw 316vh #fff, 74vw 139vh #fff, 75vw 457vh #fff, 76vw 606vh #fff, 77vw 449vh #fff, 78vw 467vh #fff, 79vw 375vh #fff, 80vw 322vh #fff, 81vw 382vh #fff,
			82vw 423vh #fff, 83vw 695vh #fff, 84vw 12vh #fff, 85vw 394vh #fff, 86vw 193vh #fff, 87vw 387vh #fff, 88vw 336vh #fff, 89vw 105vh #fff, 90vw 551vh #fff,
			91vw 313vh #fff, 92vw 115vh #fff, 93vw 593vh #fff, 94vw 421vh #fff, 95vw 43vh #fff, 96vw 597vh #fff, 97vw 414vh #fff, 98vw 124vh #fff, 99vw 660vh #fff,
			100vw 676vh #fff, 1vw 222vh #fff, 2vw 328vh #fff, 3vw 555vh #fff, 4vw 32vh #fff, 5vw 11vh #fff, 6vw 568vh #fff, 7vw 365vh #fff, 8vw 41vh #fff,
			9vw 485vh #fff, 10vw 580vh #fff, 11vw 366vh #fff, 12vw 543vh #fff, 13vw 202vh #fff, 14vw 588vh #fff, 15vw 294vh #fff, 16vw 155vh #fff, 17vw 504vh #fff,
			18vw 232vh #fff, 19vw 324vh #fff, 20vw 698vh #fff, 21vw 55vh #fff, 22vw 501vh #fff, 23vw 632vh #fff, 24vw 225vh #fff, 25vw 386vh #fff, 26vw 2vh #fff,
			27vw 484vh #fff, 28vw 689vh #fff, 29vw 589vh #fff, 30vw 358vh #fff, 31vw 5vh #fff, 32vw 119vh #fff, 33vw 288vh #fff, 34vw 404vh #fff, 35vw 178vh #fff,
			36vw 667vh #fff, 37vw 670vh #fff, 38vw 436vh #fff, 39vw 564vh #fff, 40vw 240vh #fff, 41vw 596vh #fff, 42vw 215vh #fff, 43vw 480vh #fff, 44vw 143vh #fff,
			45vw 133vh #fff, 46vw 659vh #fff, 47vw 617vh #fff, 48vw 65vh #fff, 49vw 592vh #fff, 50vw 412vh #fff, 51vw 81vh #fff, 52vw 552vh #fff, 53vw 194vh #fff,
			54vw 547vh #fff, 55vw 198vh #fff, 56vw 428vh #fff, 57vw 303vh #fff, 58vw 419vh #fff, 59vw 441vh #fff, 60vw 522vh #fff, 61vw 267vh #fff, 62vw 62vh #fff,
			63vw 626vh #fff, 64vw 390vh #fff, 65vw 528vh #fff, 66vw 553vh #fff, 67vw 649vh #fff, 68vw 427vh #fff, 69vw 516vh #fff, 70vw 7vh #fff, 71vw 678vh #fff,
			72vw 338vh #fff, 73vw 149vh #fff, 74vw 498vh #fff, 75vw 123vh #fff, 76vw 227vh #fff, 77vw 34vh #fff, 78vw 399vh #fff, 79vw 546vh #fff, 80vw 186vh #fff,
			81vw 299vh #fff, 82vw 562vh #fff, 83vw 348vh #fff, 84vw 331vh #fff, 85vw 468vh #fff, 86vw 489vh #fff, 87vw 168vh #fff, 88vw 631vh #fff, 89vw 693vh #fff,
			90vw 98vh #fff, 91vw 665vh #fff, 92vw 622vh #fff, 93vw 339vh #fff, 94vw 92vh #fff, 95vw 97vh #fff, 96vw 602vh #fff, 97vw 545vh #fff, 98vw 611vh #fff,
			99vw 656vh #fff, 100vw 662vh #fff, 1vw 274vh #fff, 2vw 116vh #fff, 3vw 246vh #fff, 4vw 435vh #fff, 5vw 33vh #fff, 6vw 205vh #fff, 7vw 305vh #fff,
			8vw 278vh #fff, 9vw 290vh #fff, 10vw 655vh #fff, 11vw 383vh #fff, 12vw 195vh #fff, 13vw 333vh #fff, 14vw 349vh #fff, 15vw 475vh #fff, 16vw 429vh #fff,
			17vw 72vh #fff, 18vw 51vh #fff, 19vw 541vh #fff, 20vw 502vh #fff, 21vw 310vh #fff, 22vw 63vh #fff, 23vw 624vh #fff, 24vw 131vh #fff, 25vw 145vh #fff,
			26vw 406vh #fff, 27vw 637vh #fff, 28vw 471vh #fff, 29vw 295vh #fff, 30vw 326vh #fff, 31vw 235vh #fff, 32vw 59vh #fff, 33vw 634vh #fff, 34vw 16vh #fff,
			35vw 203vh #fff, 36vw 78vh #fff, 37vw 681vh #fff, 38vw 400vh #fff, 39vw 478vh #fff, 40vw 135vh #fff, 41vw 548vh #fff, 42vw 445vh #fff, 43vw 325vh #fff,
			44vw 179vh #fff, 45vw 384vh #fff, 46vw 280vh #fff, 47vw 17vh #fff, 48vw 74vh #fff, 49vw 595vh #fff, 50vw 27vh #fff, 51vw 230vh #fff, 52vw 84vh #fff,
			53vw 523vh #fff, 54vw 226vh #fff, 55vw 113vh #fff, 56vw 373vh #fff, 57vw 137vh #fff, 58vw 395vh #fff, 59vw 272vh #fff, 60vw 417vh #fff, 61vw 308vh #fff,
			62vw 410vh #fff, 63vw 335vh #fff, 64vw 420vh #fff, 65vw 361vh #fff, 66vw 438vh #fff, 67vw 524vh #fff, 68vw 45vh #fff, 69vw 121vh #fff, 70vw 132vh #fff,
			71vw 245vh #fff, 72vw 99vh #fff, 73vw 61vh #fff, 74vw 60vh #fff, 75vw 315vh #fff, 76vw 526vh #fff, 77vw 69vh #fff, 78vw 411vh #fff, 79vw 86vh #fff,
			80vw 258vh #fff, 81vw 640vh #fff, 82vw 668vh #fff, 83vw 565vh #fff, 84vw 293vh #fff, 85vw 581vh #fff, 86vw 646vh #fff, 87vw 250vh #fff, 88vw 154vh #fff,
			89vw 269vh #fff, 90vw 54vh #fff, 91vw 161vh #fff, 92vw 20vh #fff, 93vw 487vh #fff, 94vw 544vh #fff, 95vw 42vh #fff, 96vw 302vh #fff, 97vw 483vh #fff,
			98vw 76vh #fff, 99vw 252vh #fff, 100vw 453vh #fff, 1vw 122vh #fff, 2vw 273vh #fff, 3vw 360vh #fff, 4vw 182vh #fff, 5vw 219vh #fff, 6vw 236vh #fff,
			7vw 190vh #fff, 8vw 367vh #fff, 9vw 607vh #fff, 10vw 636vh #fff, 11vw 265vh #fff, 12vw 691vh #fff, 13vw 434vh #fff, 14vw 389vh #fff, 15vw 620vh #fff,
			16vw 472vh #fff, 17vw 102vh #fff, 18vw 243vh #fff, 19vw 261vh #fff, 20vw 164vh #fff, 21vw 554vh #fff, 22vw 279vh #fff, 23vw 532vh #fff, 24vw 80vh #fff,
			25vw 576vh #fff, 26vw 657vh #fff, 27vw 673vh #fff, 28vw 52vh #fff, 29vw 507vh #fff, 30vw 405vh #fff, 31vw 376vh #fff, 32vw 260vh #fff, 33vw 561vh #fff,
			34vw 234vh #fff, 35vw 36vh #fff, 36vw 393vh #fff, 37vw 263vh #fff, 38vw 430vh #fff, 39vw 68vh #fff, 40vw 53vh #fff, 41vw 323vh #fff, 42vw 173vh #fff,
			43vw 281vh #fff, 44vw 381vh #fff, 45vw 424vh #fff, 46vw 654vh #fff, 47vw 465vh #fff, 48vw 440vh #fff, 49vw 539vh #fff, 50vw 470vh #fff, 51vw 287vh #fff,
			52vw 398vh #fff, 53vw 413vh #fff, 54vw 520vh #fff, 55vw 442vh #fff, 56vw 270vh #fff, 57vw 151vh #fff, 58vw 687vh #fff, 59vw 19vh #fff, 60vw 18vh #fff,
			61vw 46vh #fff, 62vw 111vh #fff, 63vw 572vh #fff, 64vw 282vh #fff, 65vw 604vh #fff, 66vw 585vh #fff, 67vw 112vh #fff, 68vw 450vh #fff, 69vw 690vh #fff,
			70vw 134vh #fff, 71vw 196vh #fff, 72vw 160vh #fff, 73vw 353vh #fff, 74vw 354vh #fff, 75vw 519vh #fff, 76vw 422vh #fff, 77vw 144vh #fff, 78vw 329vh #fff,
			79vw 259vh #fff, 80vw 130vh #fff, 81vw 95vh #fff, 82vw 209vh #fff, 83vw 534vh #fff, 84vw 244vh #fff, 85vw 197vh #fff, 86vw 8vh #fff, 87vw 29vh #fff,
			88vw 653vh #fff, 89vw 359vh #fff, 90vw 181vh #fff, 91vw 309vh #fff, 92vw 416vh #fff, 93vw 350vh #fff, 94vw 699vh #fff, 95vw 563vh #fff, 96vw 652vh #fff,
			97vw 317vh #fff, 98vw 216vh #fff, 99vw 171vh #fff, 100vw 214vh #fff, 1vw 207vh #fff, 2vw 567vh #fff, 3vw 473vh #fff, 4vw 21vh #fff, 5vw 301vh #fff,
			6vw 262vh #fff, 7vw 185vh #fff, 8vw 694vh #fff, 9vw 204vh #fff, 10vw 47vh #fff, 11vw 586vh #fff, 12vw 148vh #fff, 13vw 463vh #fff, 14vw 378vh #fff,
			15vw 220vh #fff, 16vw 22vh #fff, 17vw 184vh #fff, 18vw 298vh #fff, 19vw 341vh #fff, 20vw 71vh #fff, 21vw 530vh #fff, 22vw 537vh #fff, 23vw 460vh #fff,
			24vw 343vh #fff, 25vw 549vh #fff, 26vw 217vh #fff, 27vw 101vh #fff, 28vw 363vh #fff, 29vw 93vh #fff, 30vw 605vh #fff, 31vw 10vh #fff, 32vw 44vh #fff,
			33vw 352vh #fff, 34vw 150vh #fff, 35vw 540vh #fff, 36vw 446vh #fff, 37vw 117vh #fff, 38vw 4vh #fff, 39vw 141vh #fff, 40vw 675vh #fff, 41vw 142vh #fff,
			42vw 286vh #fff, 43vw 557vh #fff, 44vw 379vh #fff, 45vw 189vh #fff, 46vw 271vh #fff, 47vw 312vh #fff, 48vw 388vh #fff, 49vw 577vh #fff, 50vw 426vh #fff,
			51vw 556vh #fff, 52vw 486vh #fff, 53vw 346vh #fff, 54vw 277vh #fff, 55vw 651vh #fff, 56vw 451vh #fff, 57vw 31vh #fff, 58vw 330vh #fff, 59vw 433vh #fff,
			60vw 627vh #fff, 61vw 628vh #fff, 62vw 601vh #fff, 63vw 49vh #fff, 64vw 56vh #fff, 65vw 362vh #fff, 66vw 497vh #fff, 67vw 645vh #fff, 68vw 664vh #fff,
			69vw 249vh #fff, 70vw 447vh #fff, 71vw 481vh #fff, 72vw 83vh #fff, 73vw 671vh #fff, 74vw 247vh #fff, 75vw 23vh #fff, 76vw 146vh #fff, 77vw 38vh #fff,
			78vw 187vh #fff, 79vw 311vh #fff, 80vw 630vh #fff, 81vw 491vh #fff, 82vw 152vh #fff, 83vw 641vh #fff, 84vw 174vh #fff, 85vw 385vh #fff, 86vw 418vh #fff,
			87vw 364vh #fff, 88vw 82vh #fff, 89vw 600vh #fff, 90vw 156vh #fff, 91vw 647vh #fff, 92vw 635vh #fff, 93vw 89vh #fff, 94vw 13vh #fff, 95vw 88vh #fff,
			96vw 680vh #fff, 97vw 633vh #fff, 98vw 570vh #fff, 99vw 138vh #fff, 100vw 529vh #fff, 1vw 233vh #fff, 2vw 603vh #fff, 3vw 296vh #fff, 4vw 229vh #fff,
			5vw 459vh #fff, 6vw 608vh #fff, 7vw 661vh #fff, 8vw 461vh #fff, 9vw 573vh #fff, 10vw 499vh #fff, 11vw 559vh #fff, 12vw 495vh #fff, 13vw 300vh #fff,
			14vw 255vh #fff, 15vw 342vh #fff, 16vw 448vh #fff, 17vw 506vh #fff, 18vw 200vh #fff, 19vw 566vh #fff, 20vw 672vh #fff, 21vw 140vh #fff, 22vw 402vh #fff,
			23vw 550vh #fff, 24vw 167vh #fff, 25vw 241vh #fff, 26vw 594vh #fff, 27vw 462vh #fff, 28vw 48vh #fff, 29vw 275vh #fff, 30vw 106vh #fff, 31vw 35vh #fff,
			32vw 67vh #fff, 33vw 415vh #fff, 34vw 517vh #fff, 35vw 437vh #fff, 36vw 408vh #fff, 37vw 443vh #fff, 38vw 37vh #fff, 39vw 107vh #fff, 40vw 658vh #fff,
			41vw 587vh #fff, 42vw 221vh #fff, 43vw 533vh #fff, 44vw 91vh #fff, 45vw 535vh #fff, 46vw 347vh #fff, 47vw 211vh #fff, 48vw 648vh #fff, 49vw 614vh #fff,
			50vw 613vh #fff, 51vw 297vh #fff, 52vw 285vh #fff, 53vw 431vh #fff, 54vw 118vh #fff, 55vw 397vh #fff, 56vw 683vh #fff, 57vw 371vh #fff, 58vw 469vh #fff,
			59vw 344vh #fff, 60vw 307vh #fff, 61vw 268vh #fff, 62vw 199vh #fff, 63vw 337vh #fff, 64vw 314vh #fff, 65vw 327vh #fff, 66vw 206vh #fff, 67vw 439vh #fff,
			68vw 223vh #fff, 69vw 351vh #fff, 70vw 518vh #fff, 71vw 30vh #fff, 72vw 77vh #fff, 73vw 292vh #fff, 74vw 291vh #fff, 75vw 213vh #fff, 76vw 625vh #fff,
			77vw 686vh #fff, 78vw 514vh #fff, 79vw 591vh #fff, 80vw 231vh #fff, 81vw 644vh #fff, 82vw 508vh #fff, 83vw 25vh #fff, 84vw 575vh #fff, 85vw 542vh #fff,
			86vw 158vh #fff, 87vw 237vh #fff, 88vw 521vh #fff, 89vw 125vh #fff, 90vw 192vh #fff, 91vw 14vh #fff, 92vw 66vh #fff, 93vw 492vh #fff, 94vw 629vh #fff,
			95vw 396vh #fff, 96vw 474vh #fff, 97vw 488vh #fff, 98vw 682vh #fff, 99vw 319vh #fff, 100vw 114vh #fff, 1vw 590vh #fff, 2vw 679vh #fff, 3vw 159vh #fff,
			4vw 109vh #fff, 5vw 208vh #fff, 6vw 503vh #fff, 7vw 454vh #fff, 8vw 509vh #fff, 9vw 94vh #fff, 10vw 126vh #fff, 11vw 304vh #fff, 12vw 24vh #fff,
			13vw 129vh #fff, 14vw 3vh #fff, 15vw 531vh #fff, 16vw 455vh #fff, 17vw 466vh #fff, 18vw 464vh #fff, 19vw 180vh #fff, 20vw 210vh #fff, 21vw 332vh #fff,
			22vw 432vh #fff, 23vw 599vh #fff, 24vw 583vh #fff, 25vw 276vh #fff, 26vw 28vh #fff, 27vw 407vh #fff, 28vw 175vh #fff, 29vw 538vh #fff, 30vw 513vh #fff,
			31vw 90vh #fff, 32vw 490vh #fff, 33vw 257vh #fff, 34vw 476vh #fff, 35vw 73vh #fff, 36vw 266vh #fff, 37vw 560vh #fff, 38vw 50vh #fff, 39vw 700vh #fff,
			40vw 321vh #fff, 41vw 669vh #fff, 42vw 612vh #fff, 43vw 251vh #fff, 44vw 283vh #fff, 45vw 320vh #fff, 46vw 228vh #fff, 47vw 619vh #fff, 48vw 610vh #fff,
			49vw 96vh #fff, 50vw 663vh #fff, 51vw 70vh #fff, 52vw 527vh #fff, 53vw 104vh #fff, 54vw 578vh #fff, 55vw 201vh #fff, 56vw 391vh #fff, 57vw 172vh #fff,
			58vw 697vh #fff, 59vw 392vh #fff, 60vw 9vh #fff, 61vw 163vh #fff, 62vw 108vh #fff, 63vw 650vh #fff, 64vw 558vh #fff, 65vw 692vh #fff, 66vw 345vh #fff,
			67vw 212vh #fff, 68vw 638vh #fff, 69vw 340vh #fff, 70vw 666vh #fff, 71vw 598vh #fff, 72vw 377vh #fff, 73vw 380vh #fff, 74vw 684vh #fff, 75vw 157vh #fff,
			76vw 170vh #fff, 77vw 494vh #fff, 78vw 621vh #fff, 79vw 183vh #fff, 80vw 403vh #fff, 81vw 482vh #fff, 82vw 688vh #fff, 83vw 26vh #fff, 84vw 253vh #fff,
			85vw 306vh #fff, 86vw 110vh #fff, 87vw 571vh #fff, 88vw 677vh #fff, 89vw 147vh #fff, 90vw 87vh #fff, 91vw 75vh #fff, 92vw 356vh #fff, 93vw 166vh #fff,
			94vw 372vh #fff, 95vw 477vh #fff, 96vw 248vh #fff, 97vw 511vh #fff, 98vw 452vh #fff, 99vw 64vh #fff, 100vw 425vh #fff;
	}

	#stars3 {
		width: 3px;
		height: 3px;
		background: transparent;
		box-shadow: 1vw 920vh #fff, 2vw 803vh #fff, 3vw 712vh #fff, 4vw 975vh #fff, 5vw 754vh #fff, 6vw 834vh #fff, 7vw 733vh #fff, 8vw 769vh #fff, 9vw 1040vh #fff,
			10vw 730vh #fff, 11vw 770vh #fff, 12vw 905vh #fff, 13vw 781vh #fff, 14vw 830vh #fff, 15vw 722vh #fff, 16vw 825vh #fff, 17vw 885vh #fff, 18vw 1009vh #fff,
			19vw 783vh #fff, 20vw 904vh #fff, 21vw 1032vh #fff, 22vw 897vh #fff, 23vw 840vh #fff, 24vw 872vh #fff, 25vw 1017vh #fff, 26vw 966vh #fff, 27vw 942vh #fff,
			28vw 998vh #fff, 29vw 813vh #fff, 30vw 708vh #fff, 31vw 836vh #fff, 32vw 980vh #fff, 33vw 1010vh #fff, 34vw 798vh #fff, 35vw 1008vh #fff, 36vw 799vh #fff,
			37vw 802vh #fff, 38vw 981vh #fff, 39vw 1042vh #fff, 40vw 894vh #fff, 41vw 784vh #fff, 42vw 814vh #fff, 43vw 831vh #fff, 44vw 759vh #fff, 45vw 860vh #fff,
			46vw 1019vh #fff, 47vw 955vh #fff, 48vw 943vh #fff, 49vw 736vh #fff, 50vw 956vh #fff, 51vw 1041vh #fff, 52vw 915vh #fff, 53vw 1013vh #fff, 54vw 987vh #fff,
			55vw 939vh #fff, 56vw 764vh #fff, 57vw 791vh #fff, 58vw 718vh #fff, 59vw 945vh #fff, 60vw 859vh #fff, 61vw 739vh #fff, 62vw 728vh #fff, 63vw 774vh #fff,
			64vw 941vh #fff, 65vw 996vh #fff, 66vw 973vh #fff, 67vw 960vh #fff, 68vw 1023vh #fff, 69vw 731vh #fff, 70vw 926vh #fff, 71vw 806vh #fff, 72vw 974vh #fff,
			73vw 899vh #fff, 74vw 737vh #fff, 75vw 854vh #fff, 76vw 812vh #fff, 77vw 978vh #fff, 78vw 1037vh #fff, 79vw 1018vh #fff, 80vw 914vh #fff, 81vw 865vh #fff,
			82vw 967vh #fff, 83vw 819vh #fff, 84vw 838vh #fff, 85vw 1043vh #fff, 86vw 747vh #fff, 87vw 1034vh #fff, 88vw 863vh #fff, 89vw 968vh #fff, 90vw 887vh #fff,
			91vw 851vh #fff, 92vw 982vh #fff, 93vw 817vh #fff, 94vw 1005vh #fff, 95vw 767vh #fff, 96vw 951vh #fff, 97vw 1044vh #fff, 98vw 1026vh #fff, 99vw 720vh #fff,
			100vw 911vh #fff, 1vw 992vh #fff, 2vw 756vh #fff, 3vw 971vh #fff, 4vw 744vh #fff, 5vw 984vh #fff, 6vw 729vh #fff, 7vw 950vh #fff, 8vw 1048vh #fff,
			9vw 852vh #fff, 10vw 704vh #fff, 11vw 714vh #fff, 12vw 879vh #fff, 13vw 900vh #fff, 14vw 977vh #fff, 15vw 873vh #fff, 16vw 765vh #fff, 17vw 1012vh #fff,
			18vw 850vh #fff, 19vw 912vh #fff, 20vw 880vh #fff, 21vw 1039vh #fff, 22vw 1027vh #fff, 23vw 732vh #fff, 24vw 710vh #fff, 25vw 738vh #fff, 26vw 858vh #fff,
			27vw 807vh #fff, 28vw 925vh #fff, 29vw 824vh #fff, 30vw 823vh #fff, 31vw 795vh #fff, 32vw 751vh #fff, 33vw 713vh #fff, 34vw 761vh #fff, 35vw 871vh #fff,
			36vw 777vh #fff, 37vw 901vh #fff, 38vw 979vh #fff, 39vw 849vh #fff, 40vw 876vh #fff, 41vw 801vh #fff, 42vw 909vh #fff, 43vw 976vh #fff, 44vw 847vh #fff,
			45vw 800vh #fff, 46vw 892vh #fff, 47vw 989vh #fff, 48vw 930vh #fff, 49vw 953vh #fff, 50vw 833vh #fff, 51vw 775vh #fff, 52vw 957vh #fff, 53vw 1014vh #fff,
			54vw 742vh #fff, 55vw 949vh #fff, 56vw 961vh #fff, 57vw 1047vh #fff, 58vw 724vh #fff, 59vw 1035vh #fff, 60vw 786vh #fff, 61vw 1024vh #fff, 62vw 869vh #fff,
			63vw 902vh #fff, 64vw 763vh #fff, 65vw 1029vh #fff, 66vw 721vh #fff, 67vw 890vh #fff, 68vw 837vh #fff, 69vw 1011vh #fff, 70vw 750vh #fff, 71vw 866vh #fff,
			72vw 785vh #fff, 73vw 944vh #fff, 74vw 875vh #fff, 75vw 963vh #fff, 76vw 778vh #fff, 77vw 882vh #fff, 78vw 762vh #fff, 79vw 936vh #fff, 80vw 940vh #fff,
			81vw 701vh #fff, 82vw 1015vh #fff, 83vw 788vh #fff, 84vw 804vh #fff, 85vw 884vh #fff, 86vw 1007vh #fff, 87vw 1033vh #fff, 88vw 1004vh #fff,
			89vw 809vh #fff, 90vw 1030vh #fff, 91vw 913vh #fff, 92vw 726vh #fff, 93vw 893vh #fff, 94vw 745vh #fff, 95vw 835vh #fff, 96vw 881vh #fff, 97vw 1046vh #fff,
			98vw 933vh #fff, 99vw 921vh #fff, 100vw 924vh #fff, 1vw 856vh #fff, 2vw 898vh #fff, 3vw 991vh #fff, 4vw 929vh #fff, 5vw 896vh #fff, 6vw 821vh #fff,
			7vw 1002vh #fff, 8vw 1001vh #fff, 9vw 877vh #fff, 10vw 1050vh #fff, 11vw 999vh #fff, 12vw 707vh #fff, 13vw 735vh #fff, 14vw 794vh #fff, 15vw 1045vh #fff,
			16vw 959vh #fff, 17vw 922vh #fff, 18vw 861vh #fff, 19vw 938vh #fff, 20vw 965vh #fff, 21vw 779vh #fff, 22vw 845vh #fff, 23vw 792vh #fff, 24vw 805vh #fff,
			25vw 820vh #fff, 26vw 841vh #fff, 27vw 757vh #fff, 28vw 782vh #fff, 29vw 931vh #fff, 30vw 934vh #fff, 31vw 753vh #fff, 32vw 985vh #fff, 33vw 853vh #fff,
			34vw 826vh #fff, 35vw 709vh #fff, 36vw 947vh #fff, 37vw 848vh #fff, 38vw 964vh #fff, 39vw 773vh #fff, 40vw 891vh #fff, 41vw 740vh #fff, 42vw 1022vh #fff,
			43vw 780vh #fff, 44vw 995vh #fff, 45vw 758vh #fff, 46vw 927vh #fff, 47vw 928vh #fff, 48vw 895vh #fff, 49vw 988vh #fff, 50vw 1021vh #fff, 51vw 842vh #fff,
			52vw 828vh #fff, 53vw 910vh #fff, 54vw 822vh #fff, 55vw 706vh #fff, 56vw 867vh #fff, 57vw 948vh #fff, 58vw 935vh #fff, 59vw 916vh #fff, 60vw 723vh #fff,
			61vw 832vh #fff, 62vw 703vh #fff, 63vw 1020vh #fff, 64vw 906vh #fff, 65vw 700vh #fff, 66vw 954vh #fff, 67vw 716vh #fff, 68vw 946vh #fff, 69vw 919vh #fff,
			70vw 793vh #fff, 71vw 983vh #fff, 72vw 815vh #fff, 73vw 796vh #fff, 74vw 1049vh #fff, 75vw 790vh #fff, 76vw 864vh #fff, 77vw 1038vh #fff, 78vw 811vh #fff,
			79vw 741vh #fff, 80vw 907vh #fff, 81vw 1036vh #fff, 82vw 857vh #fff, 83vw 749vh #fff, 84vw 719vh #fff, 85vw 843vh #fff, 86vw 1003vh #fff, 87vw 917vh #fff,
			88vw 874vh #fff, 89vw 888vh #fff, 90vw 725vh #fff, 91vw 1028vh #fff, 92vw 743vh #fff, 93vw 932vh #fff, 94vw 816vh #fff, 95vw 993vh #fff, 96vw 844vh #fff,
			97vw 711vh #fff, 98vw 1000vh #fff, 99vw 810vh #fff, 100vw 878vh #fff, 1vw 923vh #fff, 2vw 808vh #fff, 3vw 705vh #fff, 4vw 818vh #fff, 5vw 752vh #fff,
			6vw 970vh #fff, 7vw 994vh #fff, 8vw 760vh #fff, 9vw 969vh #fff, 10vw 997vh #fff, 11vw 903vh #fff, 12vw 986vh #fff, 13vw 702vh #fff, 14vw 870vh #fff,
			15vw 789vh #fff, 16vw 772vh #fff, 17vw 958vh #fff, 18vw 771vh #fff, 19vw 839vh #fff, 20vw 734vh #fff, 21vw 727vh #fff, 22vw 918vh #fff, 23vw 797vh #fff,
			24vw 1031vh #fff, 25vw 889vh #fff, 26vw 776vh #fff, 27vw 962vh #fff, 28vw 829vh #fff, 29vw 1025vh #fff, 30vw 717vh #fff, 31vw 768vh #fff, 32vw 1016vh #fff,
			33vw 1006vh #fff, 34vw 746vh #fff, 35vw 883vh #fff, 36vw 886vh #fff, 37vw 990vh #fff, 38vw 827vh #fff, 39vw 908vh #fff, 40vw 937vh #fff, 41vw 952vh #fff,
			42vw 787vh #fff, 43vw 755vh #fff, 44vw 748vh #fff, 45vw 766vh #fff, 46vw 972vh #fff, 47vw 846vh #fff, 48vw 868vh #fff, 49vw 855vh #fff, 50vw 862vh #fff,
			51vw 715vh #fff;
		animation: animStar 200s linear infinite;
	}

	#stars3:after {
		content: " ";
		position: absolute;
		width: 3px;
		height: 3px;
		background: transparent;
		box-shadow: 1vw 920vh #fff, 2vw 803vh #fff, 3vw 712vh #fff, 4vw 975vh #fff, 5vw 754vh #fff, 6vw 834vh #fff, 7vw 733vh #fff, 8vw 769vh #fff, 9vw 1040vh #fff,
			10vw 730vh #fff, 11vw 770vh #fff, 12vw 905vh #fff, 13vw 781vh #fff, 14vw 830vh #fff, 15vw 722vh #fff, 16vw 825vh #fff, 17vw 885vh #fff, 18vw 1009vh #fff,
			19vw 783vh #fff, 20vw 904vh #fff, 21vw 1032vh #fff, 22vw 897vh #fff, 23vw 840vh #fff, 24vw 872vh #fff, 25vw 1017vh #fff, 26vw 966vh #fff, 27vw 942vh #fff,
			28vw 998vh #fff, 29vw 813vh #fff, 30vw 708vh #fff, 31vw 836vh #fff, 32vw 980vh #fff, 33vw 1010vh #fff, 34vw 798vh #fff, 35vw 1008vh #fff, 36vw 799vh #fff,
			37vw 802vh #fff, 38vw 981vh #fff, 39vw 1042vh #fff, 40vw 894vh #fff, 41vw 784vh #fff, 42vw 814vh #fff, 43vw 831vh #fff, 44vw 759vh #fff, 45vw 860vh #fff,
			46vw 1019vh #fff, 47vw 955vh #fff, 48vw 943vh #fff, 49vw 736vh #fff, 50vw 956vh #fff, 51vw 1041vh #fff, 52vw 915vh #fff, 53vw 1013vh #fff, 54vw 987vh #fff,
			55vw 939vh #fff, 56vw 764vh #fff, 57vw 791vh #fff, 58vw 718vh #fff, 59vw 945vh #fff, 60vw 859vh #fff, 61vw 739vh #fff, 62vw 728vh #fff, 63vw 774vh #fff,
			64vw 941vh #fff, 65vw 996vh #fff, 66vw 973vh #fff, 67vw 960vh #fff, 68vw 1023vh #fff, 69vw 731vh #fff, 70vw 926vh #fff, 71vw 806vh #fff, 72vw 974vh #fff,
			73vw 899vh #fff, 74vw 737vh #fff, 75vw 854vh #fff, 76vw 812vh #fff, 77vw 978vh #fff, 78vw 1037vh #fff, 79vw 1018vh #fff, 80vw 914vh #fff, 81vw 865vh #fff,
			82vw 967vh #fff, 83vw 819vh #fff, 84vw 838vh #fff, 85vw 1043vh #fff, 86vw 747vh #fff, 87vw 1034vh #fff, 88vw 863vh #fff, 89vw 968vh #fff, 90vw 887vh #fff,
			91vw 851vh #fff, 92vw 982vh #fff, 93vw 817vh #fff, 94vw 1005vh #fff, 95vw 767vh #fff, 96vw 951vh #fff, 97vw 1044vh #fff, 98vw 1026vh #fff, 99vw 720vh #fff,
			100vw 911vh #fff, 1vw 992vh #fff, 2vw 756vh #fff, 3vw 971vh #fff, 4vw 744vh #fff, 5vw 984vh #fff, 6vw 729vh #fff, 7vw 950vh #fff, 8vw 1048vh #fff,
			9vw 852vh #fff, 10vw 704vh #fff, 11vw 714vh #fff, 12vw 879vh #fff, 13vw 900vh #fff, 14vw 977vh #fff, 15vw 873vh #fff, 16vw 765vh #fff, 17vw 1012vh #fff,
			18vw 850vh #fff, 19vw 912vh #fff, 20vw 880vh #fff, 21vw 1039vh #fff, 22vw 1027vh #fff, 23vw 732vh #fff, 24vw 710vh #fff, 25vw 738vh #fff, 26vw 858vh #fff,
			27vw 807vh #fff, 28vw 925vh #fff, 29vw 824vh #fff, 30vw 823vh #fff, 31vw 795vh #fff, 32vw 751vh #fff, 33vw 713vh #fff, 34vw 761vh #fff, 35vw 871vh #fff,
			36vw 777vh #fff, 37vw 901vh #fff, 38vw 979vh #fff, 39vw 849vh #fff, 40vw 876vh #fff, 41vw 801vh #fff, 42vw 909vh #fff, 43vw 976vh #fff, 44vw 847vh #fff,
			45vw 800vh #fff, 46vw 892vh #fff, 47vw 989vh #fff, 48vw 930vh #fff, 49vw 953vh #fff, 50vw 833vh #fff, 51vw 775vh #fff, 52vw 957vh #fff, 53vw 1014vh #fff,
			54vw 742vh #fff, 55vw 949vh #fff, 56vw 961vh #fff, 57vw 1047vh #fff, 58vw 724vh #fff, 59vw 1035vh #fff, 60vw 786vh #fff, 61vw 1024vh #fff, 62vw 869vh #fff,
			63vw 902vh #fff, 64vw 763vh #fff, 65vw 1029vh #fff, 66vw 721vh #fff, 67vw 890vh #fff, 68vw 837vh #fff, 69vw 1011vh #fff, 70vw 750vh #fff, 71vw 866vh #fff,
			72vw 785vh #fff, 73vw 944vh #fff, 74vw 875vh #fff, 75vw 963vh #fff, 76vw 778vh #fff, 77vw 882vh #fff, 78vw 762vh #fff, 79vw 936vh #fff, 80vw 940vh #fff,
			81vw 701vh #fff, 82vw 1015vh #fff, 83vw 788vh #fff, 84vw 804vh #fff, 85vw 884vh #fff, 86vw 1007vh #fff, 87vw 1033vh #fff, 88vw 1004vh #fff,
			89vw 809vh #fff, 90vw 1030vh #fff, 91vw 913vh #fff, 92vw 726vh #fff, 93vw 893vh #fff, 94vw 745vh #fff, 95vw 835vh #fff, 96vw 881vh #fff, 97vw 1046vh #fff,
			98vw 933vh #fff, 99vw 921vh #fff, 100vw 924vh #fff, 1vw 856vh #fff, 2vw 898vh #fff, 3vw 991vh #fff, 4vw 929vh #fff, 5vw 896vh #fff, 6vw 821vh #fff,
			7vw 1002vh #fff, 8vw 1001vh #fff, 9vw 877vh #fff, 10vw 1050vh #fff, 11vw 999vh #fff, 12vw 707vh #fff, 13vw 735vh #fff, 14vw 794vh #fff, 15vw 1045vh #fff,
			16vw 959vh #fff, 17vw 922vh #fff, 18vw 861vh #fff, 19vw 938vh #fff, 20vw 965vh #fff, 21vw 779vh #fff, 22vw 845vh #fff, 23vw 792vh #fff, 24vw 805vh #fff,
			25vw 820vh #fff, 26vw 841vh #fff, 27vw 757vh #fff, 28vw 782vh #fff, 29vw 931vh #fff, 30vw 934vh #fff, 31vw 753vh #fff, 32vw 985vh #fff, 33vw 853vh #fff,
			34vw 826vh #fff, 35vw 709vh #fff, 36vw 947vh #fff, 37vw 848vh #fff, 38vw 964vh #fff, 39vw 773vh #fff, 40vw 891vh #fff, 41vw 740vh #fff, 42vw 1022vh #fff,
			43vw 780vh #fff, 44vw 995vh #fff, 45vw 758vh #fff, 46vw 927vh #fff, 47vw 928vh #fff, 48vw 895vh #fff, 49vw 988vh #fff, 50vw 1021vh #fff, 51vw 842vh #fff,
			52vw 828vh #fff, 53vw 910vh #fff, 54vw 822vh #fff, 55vw 706vh #fff, 56vw 867vh #fff, 57vw 948vh #fff, 58vw 935vh #fff, 59vw 916vh #fff, 60vw 723vh #fff,
			61vw 832vh #fff, 62vw 703vh #fff, 63vw 1020vh #fff, 64vw 906vh #fff, 65vw 700vh #fff, 66vw 954vh #fff, 67vw 716vh #fff, 68vw 946vh #fff, 69vw 919vh #fff,
			70vw 793vh #fff, 71vw 983vh #fff, 72vw 815vh #fff, 73vw 796vh #fff, 74vw 1049vh #fff, 75vw 790vh #fff, 76vw 864vh #fff, 77vw 1038vh #fff, 78vw 811vh #fff,
			79vw 741vh #fff, 80vw 907vh #fff, 81vw 1036vh #fff, 82vw 857vh #fff, 83vw 749vh #fff, 84vw 719vh #fff, 85vw 843vh #fff, 86vw 1003vh #fff, 87vw 917vh #fff,
			88vw 874vh #fff, 89vw 888vh #fff, 90vw 725vh #fff, 91vw 1028vh #fff, 92vw 743vh #fff, 93vw 932vh #fff, 94vw 816vh #fff, 95vw 993vh #fff, 96vw 844vh #fff,
			97vw 711vh #fff, 98vw 1000vh #fff, 99vw 810vh #fff, 100vw 878vh #fff, 1vw 923vh #fff, 2vw 808vh #fff, 3vw 705vh #fff, 4vw 818vh #fff, 5vw 752vh #fff,
			6vw 970vh #fff, 7vw 994vh #fff, 8vw 760vh #fff, 9vw 969vh #fff, 10vw 997vh #fff, 11vw 903vh #fff, 12vw 986vh #fff, 13vw 702vh #fff, 14vw 870vh #fff,
			15vw 789vh #fff, 16vw 772vh #fff, 17vw 958vh #fff, 18vw 771vh #fff, 19vw 839vh #fff, 20vw 734vh #fff, 21vw 727vh #fff, 22vw 918vh #fff, 23vw 797vh #fff,
			24vw 1031vh #fff, 25vw 889vh #fff, 26vw 776vh #fff, 27vw 962vh #fff, 28vw 829vh #fff, 29vw 1025vh #fff, 30vw 717vh #fff, 31vw 768vh #fff, 32vw 1016vh #fff,
			33vw 1006vh #fff, 34vw 746vh #fff, 35vw 883vh #fff, 36vw 886vh #fff, 37vw 990vh #fff, 38vw 827vh #fff, 39vw 908vh #fff, 40vw 937vh #fff, 41vw 952vh #fff,
			42vw 787vh #fff, 43vw 755vh #fff, 44vw 748vh #fff, 45vw 766vh #fff, 46vw 972vh #fff, 47vw 846vh #fff, 48vw 868vh #fff, 49vw 855vh #fff, 50vw 862vh #fff,
			51vw 715vh #fff;
	}

	#title {
		left: 0;
		right: 0;
		text-align: center;
		letter-spacing: 10px;
		position: relative;
	}

	#gallery1 {
		left: 0;
		right: 0;
		text-align: center;
		letter-spacing: 10px;
		position: relative;
	}

	#gallery2 {
		top: 75vh;
		left: 0;
		right: 0;
		letter-spacing: 10px;
		text-align: center;
		position: relative;
	}

	#gallery3 {
		top: 100vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery4 {
		top: 145vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery5 {
		top: 190vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery6 {
		top: 235vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery7 {
		top: 280vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery8 {
		top: 325vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery9 {
		top: 370vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#gallery10 {
		top: 415vh;
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#footer {
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	#title h1 {
		background: -webkit-linear-gradient(yellow, #ff0d86, #31ed31);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 6em;
	}

	#title h2 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 4em;
	}

	#title h3 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 3em;
	}

	h3 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 3em;
	}

	#title h4 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Mukta Vaani", "cursive";
		font-weight: 400;
		font-size: 2em;
	}

	@keyframes animStar {
		from {
			transform: translateY(0vh);
		}
		to {
			transform: translateY(-400vh);
		}
	}

	.myspace {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		margin: 0 10px 0 10px;
	}

	.card {
		background-color: rgba(255, 255, 255, 0.1) !important;
	}
</style>
